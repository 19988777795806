import React, { useReducer, useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box, TextField, Button, Grid, Autocomplete, styled, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { updateProject, getProject } from '../../redux-store/currentUserActions';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import coreUtils from '../../utils/coreUtils';
import { useS3Upload } from '../../utils/useS3Upload';
import { LabelButton } from '../Common/CustomButton';
import MainLayout from '../../layouts/MainLayout';
import {ExpandMore} from '@mui/icons-material';
import TextEditor from "../Common/TextEditor/TextEditor.jsx";
const industryTypes = [
    'Technology',
    'Healthcare',
    'Finance',
    'Consumer Goods',
    'Energy',
    'Retail',
    'Automotive',
    'Real Estate',
    'Transportation',
    'Education',
    'Media & Entertainment',
    'Agriculture',
    'Construction',
    'Hospitality',
    'Aerospace',
    'Manufacturing',
    'Chemicals',
    'Telecommunications',
    'Biotechnology',
    'Pharmaceuticals',
    'Insurance',
    'Food & Beverage',
    'Apparel',
    'Household Products',
    'Oil & Gas',
    'Renewable Energy',
    'Utilities',
    'E-commerce',
    'Logistics',
    'Shipping',
    'K-12 Education',
    'Higher Education',
    'E-Learning',
    'Film',
    'Television',
    'Music',
    'Farming',
    'AgriTech',
    'Food Production',
    'Defense',
    'Aviation',
    'Space',
    'Industrial Manufacturing',
    'Commercial Real Estate',
    'Residential Real Estate',
    'Property Management',
    'Hotels',
    'Restaurants',
    'Travel'
];

const CustomTypography = (props) => (
  <Typography
    sx={{ fontFamily: "Inter, sans-serif", my: 1/2 }}
  >
    {props.children}
  </Typography>
);

const CustomTextField = ({children, ...props}) => (
  <TextField
    sx={{ fontFamily: "Inter, sans-serif", my: 1/2 }} {...props}
  >
    {children}
  </TextField>
);

const PublicPage = ({ project, onUpdate }) => {
  const dispatch = useDispatch();
  
  const [state, setState] = useReducer(reducer, {
    editorData: project?.description || "",
    slug: project?.slug || "",
    companyName: project?.company_name || "",
    logoUrl: project?.logo?.url || "",
    facebookUrl: project?.facebook_url || "",
    linkedinUrl: project?.linkedin_url || "",
    twitterUrl: project?.twitter_url || "",
    instagramUrl: project?.instagram_url || "",
    address: project?.address || "",
    industry: project?.industry || "",
    industryType: project?.industry_type || "",
    seoTitle: project?.seo_title || "",
    seoDescription: project?.seo_description || "",
    seoKeywords: project?.seo_keywords ? project?.seo_keywords.join(",") : [],
    favIconUrl: project?.fav_icon?.url || "",
    projectDocs: project?.project_docs?.length > 0 ? project?.project_docs : [{title: "", url: "", id: new Date().getTime(), isNew: true}],
    errors: {},
    uploadError: ""
  });
  const [expanedSection, setExpanedSection] = useState("doc-detail");
  const fileInputRef = useRef(null);
  const favIconFileInputRef = useRef(null);
  const {openSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const initialMount = useRef(true);
  const [showEditor, setShowEditor] = useState(false);
  const [isSourceCodeMode, setIsSourceCodeMode] = useState(false);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      fetchProject();
    } else {
      setInitialState(project)
    }
  }, []);

  const fetchProject = async () => {
    const response = await dispatch(getProject({projectId: project?.id}));
    if (response?.payload) {
      setInitialState(response.payload);
    }
  }

  const onEditorChange = (content) => {
    setState({
      type: "SET_STATE",
      payload: { editorData: content },
    });
  };

  const handleToggleEditor = () => {
    setShowEditor(prevShowEditor => !prevShowEditor);
  };

  const handleToggleSourceCodeMode = () => {
    setIsSourceCodeMode(!isSourceCodeMode);
  };

  const setInitialState = (data) => {
    setState({
      type: "SET_STATE",
      payload: {
        editorData: data?.description, 
        slug: data?.slug || "",
        companyName: data?.company_name || "",
        logoUrl: data?.logo?.url || "",
        facebookUrl: data?.facebook_url || "",
        linkedinUrl: data?.linkedin_url || "",
        twitterUrl: data?.twitter_url || "",
        instagramUrl: data?.instagram_url || "",
        address: data?.address || "",
        industry: data?.industry || "",
        industryType: data?.industry_type || "",
        seoTitle: project?.seo_title || "",
        seoDescription: project?.seo_description || "",
        seoKeywords: project?.seo_keywords ? project?.seo_keywords.join(",") : "",
        favIconUrl: project?.fav_icon?.url || "",
        projectDocs: project?.project_docs?.length > 0 ? project?.project_docs : [{title: "", url: "", id: new Date().getTime(), isNew: true}],
      }
    })
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const { uploadFile, progress } =
    useS3Upload({
      AWS_ACCESS_KEY_ID: 'AKIA33HSNZ4X5NKBPPXB',
      AWS_SECRET_ACCESS_KEY: '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',
      AWS_BUCKET: 'qodex-temp',
      AWS_REGION: 'ap-southeast-1',
    });

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (!file) {
        alert('No file selected!');
        return;
      }
      setState({
        type: "SET_STATE",
        payload: {
          uploadError: ""
        }
      });

      uploadFile(file)
        .then(({ data, url }) => {
          console.log(url);
          setState({
            type: "SET_STATE",
            payload: {
              logoUrl: url,
              uploadError: ""
            }
          });
        })
        .catch((error) => {
          setState({
            type: "SET_STATE",
            payload: {
              uploadError: "Upload failed. Please try again."
            }
          });
        })
        .finally(() => {
          fileInputRef.current.value = '';
        });
    };
    const handleFavIconButtonClick = () => {
      favIconFileInputRef.current.click();
    }
    const handleFavIconChange = async (event) => {
      const file = event.target.files[0];
      if (!file) {
        alert('No file selected!');
        return;
      }
      setState({
        type: "SET_STATE",
        payload: {
          uploadError: ""
        }
      });

      uploadFile(file)
        .then(({ data, url }) => {
          console.log(url);
          setState({
            type: "SET_STATE",
            payload: {
              favIconUrl: url,
              uploadError: ""
            }
          });
        })
        .catch((error) => {
          setState({
            type: "SET_STATE",
            payload: {
              uploadError: "Upload failed. Please try again."
            }
          });
        })
        .finally(() => {
          favIconFileInputRef.current.value = '';
        });
    };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({
      type: "SET_STATE",
      payload: { [name]: value },
    });
  };

  const validate = () => {
    let errors = {}, error = false;
    if (!coreUtils.isStringInvalidOrBlank(state.facebookUrl) && !coreUtils.isValidUrl(state.facebookUrl)) { errors["facebookUrl"] = "Invalid Url"; error = true; }
    if (!coreUtils.isStringInvalidOrBlank(state.instagramUrl) && !coreUtils.isValidUrl(state.instagramUrl)) { errors["instagramUrl"] = "Invalid Url"; error = true; }
    if (!coreUtils.isStringInvalidOrBlank(state.twitterUrl) && !coreUtils.isValidUrl(state.twitterUrl)) { errors["twitterUrl"] = "Invalid Url"; error = true; }
    if (!coreUtils.isStringInvalidOrBlank(state.linkedinUrl) && !coreUtils.isValidUrl(state.linkedinUrl)) { errors["linkedinUrl"] = "Invalid Url"; error = true; }

    setState({
      type: "SET_STATE",
      payload: {
        errors: errors,
      },
    });

    return error;
  }

  const filterDocs = (docs) => {
    return docs.map((doc) => {
      if (doc.isNew) {
        return {
          title: doc.title, url: doc.url
        }
      } else {
        return {
          title: doc.title, url: doc.url, id: doc.id
        }
      }
    }).filter((doc) => (!doc.id ? (doc?.url?.trim() && doc?.title?.trim()) : true));
  }

  const onFormSubmit = async (data) => {
    let error = validate();
    if (error) return;
    setIsLoading(true);
    setShowEditor(false);
    setIsSourceCodeMode(false);
    const response = await dispatch(updateProject({
      description: state?.editorData || "",
      id: project?.id,
      slug: state?.slug,
      public_docs_enabled: state?.publicDocsEnabled,
      company_name: state?.companyName?.trim(),
      logo_id: state?.logoUrl,
      logo_url: state?.logoUrl,
      facebook_url: state?.facebookUrl?.trim(),
      linkedin_url: state?.linkedinUrl?.trim(),
      twitter_url: state?.twitterUrl?.trim(),
      instagram_url: state?.instagramUrl?.trim(),
      address: state?.address?.trim(),
      industry: state?.industry?.trim(),
      industry_type: state?.industryType?.trim(),
      seo_title: state?.seoTitle?.trim() || "",
      seo_description: state?.seoDescription?.trim() || "",
      seo_keywords: state?.seoKeywords?.trim() ? state?.seoKeywords?.trim()?.split(",") : [],
      fav_icon_id: state?.favIconUrl,
      fav_icon_url: state?.favIconUrl,
      project_docs_attributes: filterDocs(state?.projectDocs)
    }));
    if (response?.payload && !response?.error) {
      if (response?.payload?.id) {
        openSnackbar({ message: "Project updated successfully!", severity: 'success' });
        onUpdate({...response.payload, total_credits: project?.total_credits} || {});
      } else {
        openSnackbar({ message: "Something went wrong, please try again!", severity: 'error' });
      }
      setIsLoading(false);
    } else {
      openSnackbar({ message: response?.payload?.error || "Project updation failed!", severity: 'error' });
      setIsLoading(false);
    }
  };

  const handleDocTitleChange = (index, value) => {
    const docs = state?.projectDocs ? [...state.projectDocs] : [];
    docs[index] = {
      ...docs[index],
      title: value
    }
    setState({
      type: "SET_STATE",
      payload: { projectDocs: [...docs] }
    });
  }

  const handleDocUrlChange = (index, value) => {
    const docs = [...state.projectDocs];
    docs[index] = {
      ...docs[index],
      url: value
    }
    setState({
      type: "SET_STATE",
      payload: { projectDocs: [...docs] }
    });
  }

  const handleAddUrlClick = () => {
    const docs = [...state.projectDocs];
    setState({
      type: "SET_STATE",
      payload: { projectDocs: [...docs, {title: "", url: "", id: new Date().getTime(), isNew: true}] },
    });
  }

  const accordionStyle = { my: 1, "&::before": {height: 0}, borderRadius: "4px" };
  const summaryStyle = {marginBottom: "16px", fontSize: "14px", fontWeight: "500", color: "rgb(87, 87, 87)", fontFamily: "Inter, sans-serif"};
  return (
    <MainLayout
      headerText="Public Page"
      withPagination={false}
    >
      <Box sx={{maxHeight: "calc(100vh - 150px)", overflow: "scroll", pb: "80px"}}>
        <Box sx={{padding: "0 32px", maxWidth: "1200px", width: "100%"}}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onFormSubmit}
                disabled={isLoading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <Accordion key="doc-detail" sx={accordionStyle} expanded={expanedSection === "doc-detail"} onChange={() => {setExpanedSection("doc-detail")}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="doc-detail"
              id="doc-detail"
            >
              <h6 style={summaryStyle}>Public Docs Detail</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomTypography>Slug</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="slug" value={state.slug} onChange={handleInputChange}
                    error={!!state.errors.slug} helperText={state.errors.slug || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Company Name</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="companyName" value={state.companyName} onChange={handleInputChange}
                    error={!!state.errors.companyName} helperText={state.errors.companyName || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography sx={{mb: 1}}>Logo</CustomTypography>
                  {state?.logoUrl && <img src={state?.logoUrl} width="100px" height="auto" style={{maxHeight: "100px"}} />}
                  {state?.logoUrl && <div style={{width: "calc(100% - 150px)", display: "inline-block", marginLeft: "10px"}}>{state?.logoUrl}</div>}
                  {state?.uploadError && <span>{state?.uploadError}</span>}
                  <Button  variant="contained" color="primary" onClick={handleButtonClick} size="small" style={{ marginTop: '8px', fontSize: "13px", display: "block", textTransform: "none" }}>
                    Upload logo
                  </Button>
                  <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".png,.jpg,.jpeg" onChange={handleFileChange} />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Facebook Url</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="facebookUrl" value={state.facebookUrl} onChange={handleInputChange}
                    error={!!state.errors.facebookUrl} helperText={state.errors.facebookUrl || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Linkedin Url</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="linkedinUrl" value={state.linkedinUrl} onChange={handleInputChange}
                    error={!!state.errors.linkedinUrl} helperText={state.errors.linkedinUrl || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Twitter Url</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="twitterUrl" value={state.twitterUrl} onChange={handleInputChange}
                    error={!!state.errors.twitterUrl} helperText={state.errors.twitterUrl || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Instagram Url</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="instagramUrl" value={state.instagramUrl} onChange={handleInputChange}
                    error={!!state.errors.instagramUrl} helperText={state.errors.instagramUrl || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Address</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="address" value={state.address} onChange={handleInputChange}
                    error={!!state.errors.address} helperText={state.errors.address || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Sector</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="industry" value={state.industry} onChange={handleInputChange}
                    error={!!state.errors.industry} helperText={state.errors.industry || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Industry Type</CustomTypography>
                  <Autocomplete fullWidth disableClearable disablePortal size="small" value={state.industryType}
                    onChange={(event, newValue) => {
                      setState({
                        type: "SET_STATE",
                        payload: { industryType: newValue },
                      });
                    }}
                    onClick={(event) => {
                      console.log('clicked')
                      console.log(event.path || event.composedPath())
                    }}
                    options={industryTypes}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder='Select industry type'
                        error={!!state.errors.companyLocation}
                        helperText={state.errors.companyLocation || ' '}
                      />
                    )}
                  />
                  {!!state.errors.companyLocation && <CustomTypography color="error" sx={{ fontSize: '0.75rem', marginLeft: '14px', lineHeight: 1.66, marginTop: '4px' }}>{state.errors.companyLocation}</CustomTypography>}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion key="sidebar-links" sx={accordionStyle} expanded={expanedSection === "sidebar-links"} onChange={() => {setExpanedSection("sidebar-links")}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="sidebar-links"
              id="sidebar-links"
            >
              <h6 style={summaryStyle}>Project Docs Sidebar Links</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {
                    state?.projectDocs?.map((doc, index) => (
                      <Grid item key={doc.id} sx={{paddingBottom: "36px"}}>
                        <CustomTypography>Title</CustomTypography>
                        <CustomTextField
                          fullWidth size='small' variant="outlined" name="docs" value={doc.title} onChange={(e) => {handleDocTitleChange(index, e.target.value)}}
                        />
                        <CustomTypography sx={{mt: "4px"}}>Url</CustomTypography>
                        <CustomTextField
                          fullWidth size='small' variant="outlined" name="url" value={doc.url} onChange={(e) => {handleDocUrlChange(index, e.target.value)}}
                        />
                      </Grid>
                    ))
                  }
                  <LabelButton onClick={handleAddUrlClick}>+ Add new link</LabelButton>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion key="seo" sx={accordionStyle} expanded={expanedSection === "seo"} onChange={() => {setExpanedSection("seo")}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="seo"
              id="seo"
            >
              <h6 style={summaryStyle}>SEO</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomTypography>Title</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="seoTitle" value={state.seoTitle} onChange={handleInputChange}
                    error={!!state.errors.seoTitle} helperText={state.errors.seoTitle || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Description</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="seoDescription" value={state.seoDescription} onChange={handleInputChange}
                    error={!!state.errors.seoDescription} helperText={state.errors.seoDescription || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography>Keywords</CustomTypography>
                  <CustomTextField
                    fullWidth size='small' variant="outlined" name="seoKeywords" value={state.seoKeywords} onChange={handleInputChange}
                    error={!!state.errors.seoKeywords} helperText={state.errors.seoKeywords || ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTypography sx={{mb: 1}}>Fav Icon</CustomTypography>
                  {state?.favIconUrl && <img src={state?.favIconUrl} width="100px" height="auto" style={{maxHeight: "100px"}} />}
                  {state?.favIconUrl && <div style={{width: "calc(100% - 150px)", display: "inline-block", marginLeft: "10px"}}>{state?.favIconUrl}</div>}
                  {state?.favIconUploadError && <span>{state?.favIconUploadError}</span>}
                  <Button  variant="contained" color="primary" onClick={handleFavIconButtonClick} size="small" style={{ marginTop: '8px', fontSize: "13px", display: "block", textTransform: "none" }}>
                    Upload Fav Icon
                  </Button>
                  <input type="file" ref={favIconFileInputRef} style={{ display: 'none' }} accept=".png,.jpg,.jpeg" onChange={handleFavIconChange} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion key="overview" sx={accordionStyle} expanded={expanedSection === "overview"} onChange={() => {setExpanedSection("overview")}}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="overview"
              id="overview"
            >
              <h6 style={summaryStyle}>Project Overview</h6>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {showEditor ? (
                  <Box sx={{px: 4, width: "100%"}}>
                    {isSourceCodeMode ? (
                      <CustomTextField
                        fullWidth
                        multiline
                        minRows={10}
                        value={state.editorData}
                        onChange={(e) => onEditorChange(e.target.value)}
                        variant="outlined"
                        margin="normal"
                      />
                    ) : (
                      <TextEditor initialContent={state.editorData} onChange={onEditorChange} />
                    )}
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        size="small"
                        variant="outlined"
                        style={{ marginRight: '10px' }}
                        onClick={handleToggleSourceCodeMode}
                      >
                        {isSourceCodeMode ? 'Switch to Editor' : 'View Source Code'}
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={handleToggleEditor}
                      >
                        Close Editor
                      </Button>
                    </div>
                  </Box>
                ) : (
                  <Box sx={{px: 4, width: "100%"}}>
                    <Box sx={{p: 2, border: "1px solid #e9e9e9", borderRadius: "4px"}}>
                      <div dangerouslySetInnerHTML={{ __html: state.editorData || 'Add overview to preview here.' }} />
                    </Box>
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        size="small"
                        variant="contained"
                        style={{ backgroundColor: '#6241d4', marginTop: '10px', fontFamily: "Inter, Sans-serif", fontWeight: "550", fontSize: "11px" }}
                        onClick={handleToggleEditor}
                      >
                        Edit
                      </Button>
                    </div>
                  </Box>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default PublicPage;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
