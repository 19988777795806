import React, { useEffect, useRef } from 'react';
import {Box, Grid, Chip, Link} from '@mui/material';
import dayjs from 'dayjs';

import qodexLogo from "../../assets/logos/logo_small.png";

const ChatContainer = ({chatItems}) => {
  const lastItemRef = useRef();

  useEffect(() => {
    lastItemRef?.current?.scrollIntoView(false, {behavior: "smooth"});
  }, [chatItems]);

  const renderItem = (item, isByUser, lastItem) => {
    if (item.type === "element") {
      return item?.items;
    } else if (item.type === "chips") {
      return (
        <>
          <Box className="bold">{item?.chatMessage}</Box>
          <Box className={`chipContainer ${isByUser && "rightSide"}`}>
            {item?.items?.map(i => (<Chip className="chip" label={i.label} key={i.id} />))}
          </Box>
          <Box className={`time ${isByUser && "rightSide"}`}>{dayjs(item?.time).format("hh:mm a")}</Box>
        </>
      )
    } else if (item.type === "process") {
      return (
        <Box className={`processContainer ${isByUser && "rightSide"}`}>
          <Box className="text">{item.chatMessage}</Box>
          <Box className="element">{item.element}</Box>
        </Box>
      );
    } else if (item.type === "link") {
      return (
        <Box className={`linkContainer ${isByUser && "rightSide"}`}>
          <Box className="link">
            <Link href={item.link} underline="always" target={item.newTab ? "_blank" : ""} rel={item.newTab ? "noreferrer noopener" : ""}>{item.chatMessage}</Link>
          </Box>
          <Box className={`time ${isByUser && "rightSide"}`}>{dayjs(item?.time).format("hh:mm a")}</Box>
        </Box>
      );
    } else {
      return (
        <>
          <Box className={`textMessage${isByUser ? " rightSide" : ""}${item?.status === "error" ? " error" : ""}`}>{item?.chatMessage}</Box>
          <Box className={`time ${isByUser && "rightSide"}`}>{dayjs(item?.time).format("hh:mm a")}</Box>
        </>
      )
    }
  }

  let content;
  if (chatItems?.length === 0) {
    content = (
      <Box className="emptyChat">
        <img className='responsive-image centerContent' src={qodexLogo} alt="qodex Logo" />
        <div>Hello there!</div>
        <div>Need any help with this request?</div>
      </Box>
    )
  } else {
    content = chatItems.map((item, index) => {
      const isLastItem = chatItems.length - 1 === index;
      const isByUser = item.createdBy === "user";
      return (
        <Grid key={item.id} className="fadeinEffect" container direction="column" justifyContent="flex-end" alignItems={isByUser ? "flex-end" : "flex-start"} ref={isLastItem ? lastItemRef : null}>
          <Box className="contentContainer" direction="column" alignItems={isByUser ? "flex-end" : "flex-start"}>
            {renderItem(item, isByUser, isLastItem)}
          </Box>
        </Grid>
      );
    })
  }

  return (
    <Box className="chatContainer">
      {content}
    </Box>
  )
}

export default ChatContainer;
