import { createSlice } from '@reduxjs/toolkit';

// TODO :: Add a separate reducer for user actions

const currentUserSlice = createSlice({
  name: 'user',
  initialState: {
    current_user: null,
    collections: [],
    folders: [],
    summaries: [],
    collectionEntities: {},
    folderEntities: {},
    selectedState: {},
    selectedSummary: {},
    selectedCollection: {},
    selectedFolder: {},
    selectedProject: {},
    selectedApi: {},
    entities: {},
    selectedTab: {},
    selectedResponseTab: 0,
    selectedResponseSubTab: 0,
    selectedSidebarState: {},
    isLoading: false,
    errors: {},
    failedTestsCounts: {},
    confirmationMessage: null,
    confirmationError: null,
    owners: []
  },
  reducers: {
    updateCurrentUser: (state, action) => {
      let new_user = action.payload;
      let access_list = {};
      new_user.access_list = access_list;
      return {
        ...state,
        current_user: { ...new_user },
      };
    },
    updateCollections: (state, action) => {
      return {
        ...state,
        collections: action.payload,
      };
    },
    updateState: (state, action) => {
      const previousData = state.selectedState;
      const {
        collection,
        folder,
        collectionApi,
        folderApi,
        collectionSummary,
      } = action.payload;

      const updatedData = {
        ...state,
        collection: collection || previousData.collection,
        folder: folder || previousData.folder,
        collectionApi: collectionApi || previousData.collectionApi,
        folderApi: folderApi || previousData.folderApi,
        collectionSummary: collectionSummary || previousData.collectionSummary,
      };

      return {
        ...state,
        selectedState: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },

    updateFolders: (state, action) => {
      const previousData = state.folders;
      const { data, collectionId } = action.payload;
      const updatedData = {
        ...state.folders,
        [collectionId]: action.payload.data.folders,
      };

      return {
        ...state,
        folders: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },

    pushFoldersIfExist: (state, action) => {
      const { folders: newFolders, collectionId } = action.payload;
      const currentFolders = state.folders[collectionId];

      if (!currentFolders) return state;

      const previousData = state.folders;
      const updatedData = {
        ...state.folders,
        [collectionId]: [...currentFolders, ...newFolders],
      };

      return {
        ...state,
        folders: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },

    removeFolderBySlug: (state, action) => {
      const { folderSlug, collectionId } = action.payload;

      const previousData = state.folders;
      const currentFolders = state.folders[collectionId];
      const updatedData = {
        ...state.folders,
        [collectionId]: currentFolders.filter(
          (folder) => folder.slug !== folderSlug
        ),
      };

      return {
        ...state,
        folders: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },

    updateCollectionEntities: (state, action) => {
      const previousData = state.collectionEntities;
      const { data, collectionId } = action.payload;
      const updatedData = {
        ...state.collectionEntities,
        [collectionId]: action.payload.data.entities,
      };

      return {
        ...state,
        collectionEntities: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },

    pushCollectionEntitiesIfExist: (state, action) => {
      const { entities: newEntities, collectionId } = action.payload;
      const currentEntities = state.collectionEntities[collectionId];

      if (!currentEntities) return state;

      const previousData = state.collectionEntities;
      const updatedData = {
        ...state.collectionEntities,
        [collectionId]: [...currentEntities, ...newEntities],
      };

      return {
        ...state,
        collectionEntities: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },

    removeCollectionEntityBySlug: (state, action) => {
      const { entitySlug, collectionId } = action.payload;

      const currentEntities = state.collectionEntities[collectionId];
      const previousData = state.collectionEntities;
      const updatedData = {
        ...state.collectionEntities,
        [collectionId]: currentEntities.filter(
          (entity) => entity.slug !== entitySlug
        ),
      };

      return {
        ...state,
        collectionEntities: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },
    // Collection API / Entity
    updateFolderEntities: (state, action) => {
      const previousData = state.folderEntities;
      const { entities, folderId } = action.payload;
      const updatedData = {
        ...state.folderEntities,
        [folderId]: entities,
      };

      return {
        ...state,
        folderEntities: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },
    pushFolderEntitiesIfExist: (state, action) => {
      const { entities: newEntities, folderId } = action.payload;
      const currentEntities = state.folderEntities[folderId];

      if (!currentEntities) return state;

      const previousData = state.folderEntities;
      const updatedData = {
        ...state.folderEntities,
        [folderId]: [...currentEntities, ...newEntities],
      };

      return {
        ...state,
        folderEntities: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },
    removeFolderEntityBySlug: (state, action) => {
      const { entitySlug, folderId } = action.payload;

      const currentEntities = state.folderEntities[folderId];
      const previousData = state.folderEntities;
      const updatedData = {
        ...state.folderEntities,
        [folderId]: currentEntities.filter(
          (entity) => entity.slug !== entitySlug
        ),
      };

      return {
        ...state,
        folderEntities: updatedData,
        previousData, // Include previous snapshot in the state if needed
      };
    },
    setState: (state, action) => {
      const {
        collection,
        folder,
        collectionApi,
        folderApi,
        collectionSummary,
      } = action.payload;

      const updatedData = {
        ...state,
        collection: collection,
        folder: folder,
        collectionApi: collectionApi,
        folderApi: folderApi,
        collectionSummary: collectionSummary,
      };

      return {
        ...state,
        selectedState: updatedData,
      };
    },
    setCollection: (state, action) => {
      return {
        ...state,
        selectedCollection: action.payload,
      };
    },
    setSidebarState: (state, action) => {
      return {
        ...state,
        selectedSidebarState: action.payload,
      };
    },
    setResourceSummary: (state, action) => {
      return {
        ...state,
        selectedSummary: action.payload,
      };
    },
    setFolder: (state, action) => {
      return {
        ...state,
        selectedFolder: action.payload,
      };
    },
    setProject: (state, action) => {
      return {
        ...state,
        selectedProject: action.payload,
      };
    },
    setApi: (state, action) => {
      return {
        ...state,
        selectedApi: action.payload,
      };
    },

    updateLoadingStage: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    updateLoadError: (state, action) => {
      let new_user = state.user;

      return {
        ...state,
        errors: action.payload.error_message,
        current_user: new_user,
      };
    },
    userLogOut: (state) => {
      return {
        ...state,
        current_user: {},
        errors: {},
      };
    },
    login: (state, action) => {
      return {
        ...state,
        current_user: action.payload,
      };
    },
    Signup: (state, action) => {
      return {
        ...state,
        current_user: action.payload,
      };
    },
    setSelectedResponseTab: (state, action) => {
      return {
        ...state,
        selectedResponseTab: action.payload,
      };
    },
    setSelectedResponseSubTab: (state, action) => {
      return {
        ...state,
        selectedResponseSubTab: action.payload,
      };
    },
    // Email confirmation messages and errors
    setConfirmationMessage: (state, action) => {
      return {
        ...state,
        confirmationMessage: action.payload,
      };
    },
    setConfirmationError: (state, action) => {
      return {
        ...state,
        confirmationError: action.payload,
      };
    },
    clearConfirmation: (state) => {
      return {
        ...state,
        confirmationMessage: null,
        confirmationError: null,
      };
    },
    ////////////////////////////////////////////////

    // Entities
    cacheEntity: (state, action) => {
      const { id, data } = action.payload;
      const newEntities = {
        ...state.entities,
        [id]: data,
      };

      return {
        ...state,
        entities: newEntities,
      };
    },
    updateEntity: (state, action) => {
      const { id, name, slug, testType, request, response } = action.payload;

      if (name || testType || slug || request || response) {
        const updatedEntity = {
          ...state.entities[id],
          ...(name && { name: name }),
          ...(testType && { test_type: testType }),
          ...(slug && { slug: slug }),
          ...(request && { request: request }),
          ...(response && { response: response }),
        };

        const newEntities = {
          ...state.entities,
          [id]: updatedEntity,
        };

        return {
          ...state,
          entities: newEntities,
        };
      }
    },
    ////////////////////////////////////////////////
    updateEntityNameFromUrl: (state, action) => {
      const { id, name } = action.payload;

      const updateNameInObjectOfArrays = (obj, id, newName) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key] && Array.isArray(obj[key]))
            obj[key] = obj[key].map((item) =>
              item.id === id ? { ...item, name: newName } : item
            );
        });
      };

      if (state.entities[id]) {
        state.entities[id].name = name;
      }

      if (state.selectedApi.id === id) {
        state.selectedApi.name = name;
      }

      // state.tabs = state.tabs.map(tab => tab.id === id ? { ...tab, name: name } : tab);

      updateNameInObjectOfArrays(state.folders, id, name);
      updateNameInObjectOfArrays(state.collectionEntities, id, name);
      updateNameInObjectOfArrays(state.folderEntities, id, name);

      // if (state.selectedState.collectionApi && state.selectedState.collectionApi.id === id) {
      //   state.selectedState.collectionApi.name = name;
      // }

      // if (state.selectedState.folderApi && state.selectedState.folderApi.id === id) {
      //   state.selectedState.folderApi.name = name;
      // }
    },
    setFlatCollectionEntities: (state, action) => {
      console.log(action);
      return {
        ...state,
        flatCollectionEntities: {
          ...state.flatCollectionEntities,
          [action.payload.collectionId]: action.payload.entities,
        },
      };
    },
    setOwners: (state, action) => {
      return {
        ...state,
        owners: action.payload || [],
      };
    },
  },
});

export const {
  updateCurrentUser,
  updateCollections,
  updateFolders,
  pushFoldersIfExist,
  removeFolderBySlug,
  updateApis,
  updateCollectionEntities,
  pushCollectionEntitiesIfExist,
  removeCollectionEntityBySlug,
  updateFolderEntities,
  pushFolderEntitiesIfExist,
  removeFolderEntityBySlug,
  updateState,
  setApi,
  setProject,
  setFolder,
  setCollection,
  setState,
  setResourceSummary,
  userLogOut,
  updateLoadingStage,
  updateLoadError,
  login,
  setSelectedResponseTab,
  setSelectedResponseSubTab,
  setSidebarState,
  setConfirmationMessage,
  setConfirmationError,
  clearConfirmation,
  cacheEntity,
  updateEntity,
  updateEntityNameFromUrl,
  setFlatCollectionEntities,
  setOwners
} = currentUserSlice.actions;

export default currentUserSlice.reducer;
