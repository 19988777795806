import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchCollectionFolders,
  fetchCollectionApis,
  fetchFoldersApis,
  destroyCollection,
  duplicateResource,
  fetchSummary,
  createCollection,
  createFolder,
  createEntity,
  updateCollection,
} from '../../redux-store/currentUserActions';
import {
  setCollection,
  setState,
  setSidebarState,
  setSelectedResponseTab,
  setSelectedResponseSubTab,
  setFolder,
  setApi,
  pushFoldersIfExist,
  removeFolderBySlug,
  pushCollectionEntitiesIfExist,
  removeCollectionEntityBySlug,
  removeFolderEntityBySlug,
} from '../../redux-store/currentUserSlice';
import { useS3Upload } from '../../utils/useS3Upload';
import _ from 'lodash';

// MUI imports
import {
  IconButton,
  Box,
  Menu,
  MenuItem,
  LinearProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
  Tooltip
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

// Component imports
import { Sidebar } from 'react-pro-sidebar';
import CollectionNavbar from './CollectionNavbar';
import ProjectPage from './ProjectPage';
import FolderComponent from './FolderComponent';
import EntityComponent from './EntityComponent';
import FolderScreen from '../Folders/FolderScreen';
import ApiScreen from '../Apis/ApiScreen';
import LoadingOverlay from '../Common/LoadingOverlay';
import ForkDialog from '../Common/ForkDialog';
import ConfirmationDialog from '../Dialog/ConfirmationDialog';
import ForkModal from './ForkModal';
import { useGuestUser } from '../../contexts/GuestUserContext';
import { selectTab, addTab, removeTab } from '../../redux-store/tabSlice';
import {
  addDocForTestCaseRerun,
  fetchCollectionsList,
  importCollection,
  makeCollectionPrivate,
  makeCollectionPublic,
  fetchPublicCollection,
  forkCollection
} from '../../redux-store/collectionActions';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';
import { fetchOrganizations } from '../../redux-store/dashboardActions';
import {
  filterProjectCollections,
  findCollectionByEntityId,
  findCollectionByFolderId,
  findCollectionBySlug,
  findEntityBySlug,
  findEntityBySlugInFolders,
  findFolderByEntityId,
  findFolderBySlug,
  findFolderById,
} from '../../utils/collectionHelpers';

// Constants
import { EntityableTypes, ScreenTypes } from '../../config/constants';

// Styles and assets
import '../../components/Dashboard/index.css';
import '../../styles/App.scss';

// TODO :: Add run manually option for Runners to the collection dropdown menu

const CollectionSidebar = ({ project, selectedCollection }) => {
  const dispatch = useDispatch();
  const { isGuestUser } = useGuestUser();
  const { collectionSlug, folderSlug, entitySlug } = useParams();
  // these refs are created to track the slugs after performing shallow routing as original params doesn't update in shallow mode
  const collectionSlugRef = useRef(collectionSlug);
  const folderSlugRef = useRef(folderSlug);
  const entitySlugRef = useRef(entitySlug);

  const collectionFolders = useSelector((state) => state.user.folders);
  const collectionEntities = useSelector(
    (state) => state.user.collectionEntities
  );
  const folderEntities = useSelector((state) => state.user.folderEntities);
  // using ref to access latest added folder entities in initial useEffect if entity slug is present
  const folderEntitiesRef = useRef(folderEntities);
  const selectedApi = useSelector((state) => state.user.selectedApi);
  const selectedState = useSelector((state) => state.user.selectedState);

  const { tabs, activeTabId } = useSelector((state) => state.tabs);
  const selectedTab = tabs.find((tab) => tab.id === activeTabId);
  const [isUserInitiatedTabChange, setIsUserInitiatedTabChange] =
    useState(false);

  const parentRef = useRef(null);
  const inputRef = useRef(null);
  const fileInputRefPostman = useRef(null);
  const fileInputRefSwagger = useRef(null);
  const fileInputRef2 = useRef(null);
  const { openSnackbar } = useSnackbar();
  const [openForkConfirm, setOpenForkConfirm] = useState(false);
  const [openPublicConfirm, setOpenPublicConfirm] = useState(false);
  const [openPrivateConfirm, setOpenPrivateConfirm] = useState(false);
  const [fileUploadForResource, setFileUploadForResource] =
    useState('Collection');
  const [selectedFolderForAddDoc, setSelectedFolderForAddDoc] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [dottedCollection, setDottedCollection] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [renaming, setRenaming] = useState({
    isActive: false,
    collectionId: null,
    newName: '',
  });
  const [openPublicForkModal, setOpenPublicForkModal] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(ScreenTypes.NONE);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [uploadError, setUploadError] = useState('');

  const [newCollectionName, setNewCollectionName] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [organizations, setOrganizations] = useState([]);

  const [projectCollections, setProjectCollections] = useState([]);
  const filteredProjectCollections = filterProjectCollections(
    projectCollections,
    searchTerm
  );
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuOpenNodeId, setMenuOpenNodeId] = useState(null);
  const [expandedNodeIds, setExpandedNodeIds] = useState([]);
  const [selectedNodeId, setSelectedNodeId] = useState('');
  const [openNodes, setOpenNodes] = useState([]);
  const buttonRefs = useRef({});
  const createRef = (id) => {
    if (!buttonRefs.current[id]) {
      buttonRefs.current[id] = React.createRef();
    }
    return buttonRefs.current[id];
  };
  const [collectionToFork, setCollectionToFork] = useState("");

  // console.log(process.env.AWS_ACCESS_KEY_ID, process.env.AWS_SECRET_ACCESS_KEY, process.env.AWS_REGION, process.env.AWS_BUCKET)

  // const { uploadFile, progress, uploadStatus, signedUrl } = useS3Upload({
  //   AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
  //   AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
  //   AWS_REGION: process.env.AWS_REGION,
  //   AWS_BUCKET: process.env.AWS_BUCKET
  // });

  const { uploadFile, progress, uploadStatus, signedUrl, resetUploadStatus } =
    useS3Upload({
      AWS_ACCESS_KEY_ID: 'AKIA33HSNZ4X5NKBPPXB',
      AWS_SECRET_ACCESS_KEY: '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',
      AWS_BUCKET: 'qodex-temp',
      AWS_REGION: 'ap-southeast-1',
    });

  useEffect(() => {
    document.addEventListener('mousedown', handleRenameClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleRenameClickOutside);
    };
  }, [renaming.newName]);

  useEffect(() => {
    refetchCollectionList();
  }, [project]);

  useEffect(() => {
    folderEntitiesRef.current = folderEntities;
  }, [folderEntities]);

  const refetchCollectionList = async () => {
    setIsLoading(true);

    const collectionResponse = await dispatch(
      fetchCollectionsList({
        projectId: project.id,
        collectionSlug: isGuestUser ? collectionSlugRef.current : '',
      })
    );

    const projectCollections = collectionResponse?.payload;
    const collection = findCollectionBySlug(
      projectCollections,
      collectionSlugRef.current
    );

    if (collection?.id)
      await Promise.all([
        fetchFolders(collection.id),
        fetchApis(collection.id),
      ]);

    setProjectCollections(projectCollections);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchOrgsAndProjects = async () => {
      const res = await dispatch(fetchOrganizations());

      if (res.payload?.length > 0) {
        setOrganizations(res.payload);
        handlePublicCollectionFork();
      }

      // if(organizationsData.length > 0 && organizationsData[0].projects.length > 0) {
      //   setSelectedProjectId(organizationsData[0].projects[0].id);
      // }
    };

    fetchOrgsAndProjects();
  }, []);

  const handlePublicCollectionFork = async () => {
    const forkCollectionId = localStorage.getItem("forkCollectionId");
    if (forkCollectionId !== null) {
      localStorage.removeItem("forkCollectionId");
      const response = await dispatch(fetchPublicCollection({collectionId: forkCollectionId}));
      setCollectionToFork(response?.payload)
      setOpenPublicForkModal(true);
    }
  }

  const handleForkPublicClose = (e, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      e.preventDefault();
      return;
    }
    setOpenPublicForkModal(false);
  }

  const handleForkPublicConfirm = async (collectionId, projectId) => {
    const response = await dispatch(forkCollection({ collectionId: collectionId, projectId: projectId }));
    if (response?.payload && !response?.payload?.error) {
      openSnackbar({ message: 'Please wait while the collection is being forked.', severity: 'info' });
      fetchCollections();
      handleForkPublicClose();
    } else {
      openSnackbar({ message: response?.payload?.error || "Failed to fork collection!", severity: 'error' });
    }
  }

  useEffect(() => {
    const init = async () => {
      dispatch(fetchCollectionsList({ projectId: project.id })).then(
        (response) => {
          const projectCollections = response?.payload;

          setIsLoading(false);

          if (projectCollections?.length > 0) {
            setProjectCollections(projectCollections);

            if (!collectionSlugRef.current) {
              const firstCollection = projectCollections[0];
              dispatch(setSidebarState(firstCollection));
              onCollectionClick({ collection: firstCollection });
            }
          }
        }
      );
    };
    // refetchCollectionList();
    // init();
  }, [project.id, dispatch]);

  useEffect(() => {
    (async () => {
      if (collectionSlugRef.current && projectCollections?.length > 0) {
        setIsLoadingScreen(true);

        const _collection = projectCollections.find(
          (c) => c.slug === collectionSlugRef.current
        );
        await handleCollectionClick(_collection?.id);

        if (_collection?.id) {
          if (folderSlugRef.current && entitySlugRef.current) {
            const _folder = findFolderBySlug(
              collectionFolders,
              folderSlugRef.current
            );
            if (_folder?.id) {
              await handleFolderClick(_folder.id);

              const _entity = findEntityBySlugInFolders(
                folderEntitiesRef.current, // using latest entities added by handleFolderClick
                entitySlugRef.current
              );

              if (_entity?.id) {
                setSelectedNodeId(_entity.id);
                onEntityClick({
                  entity: _entity,
                  folder: _folder,
                  collection: _collection,
                });
              }
            }
          } else if (folderSlugRef.current) {
            const _folder = findFolderBySlug(
              collectionFolders,
              folderSlugRef.current
            );
            if (_folder?.id) {
              setSelectedNodeId(_folder.id);
              onFolderClick({ folder: _folder, collection: _collection });
            }
          } else if (entitySlugRef.current) {
            const _entity = findEntityBySlug(
              collectionEntities,
              entitySlugRef.current
            );
            if (_entity?.id) {
              setSelectedNodeId(_entity.id);
              onEntityClick({ entity: _entity, collection: _collection });
            }
          } else {
            onCollectionClick({ collection: _collection });
            setSelectedNodeId(_collection.id);
          }
        } else {
          collectionSlugRef.current = '';
          folderSlugRef.current = '';
          entitySlugRef.current = '';

          window.history.replaceState(null, '', `/collections`);
        }

        setTimeout(() => {
          setIsLoadingScreen(false);
        }, 1000);
      }
    })();
  }, [projectCollections]);

  useEffect(() => {
    if (isUserInitiatedTabChange) {
      if (selectedTab?.resource?.entityable_id) {
        const _folder =
          selectedTab?.resource?.entityable_type === 'Folder'
            ? findFolderByEntityId(folderEntities, selectedTab?.resource?.id)
            : null;
        const _collection =
          selectedTab?.resource?.entityable_type === 'Collection'
            ? findCollectionByFolderId(
                collectionFolders,
                projectCollections,
                _folder?.id
              ) ||
              findCollectionByEntityId(
                collectionEntities,
                projectCollections,
                selectedTab?.resource?.id
              )
            : null;
        onEntityClick({
          entity: selectedTab?.resource,
          folder: _folder,
          collection: _collection,
        });
      } else if (selectedTab?.resource?.collection_id) {
        const _collection = findCollectionByFolderId(
          collectionFolders,
          projectCollections,
          selectedTab?.id
        );
        onFolderClick({
          folder: selectedTab?.resource,
          collection: _collection,
        });
      } else if (selectedTab?.resource?.project_id) {
        onCollectionClick({ collection: selectedTab?.resource });
      }

      setIsUserInitiatedTabChange(false);
    }
  }, [selectedTab, isUserInitiatedTabChange]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchCollections = async () => {
    refetchCollectionList();
    // const response = await dispatch(fetchCollectionsList({ projectId: project.id, collectionSlug: isGuestUser ? collectionSlugRef.current : '' }));
    // if (response.payload) setProjectCollections(response.payload);
    // console.log(response);
    // setIsLoading(false);
  };

  const fetchFolders = async (collectionId) => {
    return dispatch(fetchCollectionFolders(collectionId));
  };

  const fetchFoldersEntities = async (folders) => {
    return dispatch(fetchFoldersApis(folders));
  };

  const fetchApis = async (collectionId) => {
    return dispatch(fetchCollectionApis(collectionId));
  };

  const handleCollectionClick = async (collectionId) => {
    if (!collectionId || expandedNodeIds.includes(collectionId)) return;

    const dataToFetch = [];

    if (!collectionFolders[collectionId])
      dataToFetch.push(fetchFolders(collectionId));
    if (!collectionEntities[collectionId])
      dataToFetch.push(fetchApis(collectionId));
    if (dataToFetch.length) await Promise.all(dataToFetch);

    setExpandedNodeIds((prevIds) => [...prevIds, collectionId]);
  };

  const handleFolderClick = async (folderId) => {
    if (!folderId || expandedNodeIds.includes(folderId)) return;

    if (!folderEntities[folderId]) await fetchFoldersEntities([folderId]);

    setExpandedNodeIds((prevIds) => [...prevIds, folderId]);
  };

  const handleRenameClickOutside = async (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      rename();
    }
  };

  const updateTabbar = (resource) => {
    const existingTab = tabs.find((tab) => tab.id === resource?.id);

    if (existingTab) {
      dispatch(selectTab(existingTab.id));
    } else {
      dispatch(
        addTab({ name: resource?.name, id: resource?.id, resource: resource })
      );
    }
  };

  const onCollectionClick = ({
    collection,
    addToTabbar = true,
    updateRoute = true,
  }) => {
    // Fetch folders and apis for the collection
    handleCollectionClick(collection.id);

    // Reset response tab to Current Response
    dispatch(setSelectedResponseTab(0));
    dispatch(setSelectedResponseSubTab(0));
    // FIXME :: When a folder is currently selected, and the user selected a collection, the ApiScreen goes away
    if (updateRoute) triggerRouteUpdate(collection?.slug);
    dispatch(setState({ collection }));
    dispatch(setCollection(collection));
    dispatch(fetchSummary(collection));
    dispatch(setSidebarState(collection));
    if (addToTabbar) updateTabbar(collection);
    setCurrentScreen(ScreenTypes.COLLECTION);
  };

  const onAddCollection = async () => {
    await dispatch(createCollection(project));
    fetchCollections();
  };

  const onCollectionMenuClick = (collection, event) => {
    event.preventDefault();
    event.stopPropagation();

    setMenuOpenNodeId(menuOpenNodeId === collection.id ? null : collection.id);

    const button = buttonRefs.current[collection.id].current;

    if (button) {
      const buttonPosition = button.getBoundingClientRect();
      const menuTop = buttonPosition.bottom - 40 + window.scrollY;
      const menuLeft = buttonPosition.left - 135 + window.scrollX;

      setMenuPosition({ top: menuTop, left: menuLeft });
    }

    if (menuOpen && dottedCollection?.id === collection.id) {
      setMenuOpen(false);
      setAnchorEl(null);
      setDottedCollection(null);
    } else {
      setAnchorEl(event.currentTarget);
      setDottedCollection(collection);
      setMenuOpen(true);
    }
  };

  const addFolderToCollection = async () => {
    const response = await dispatch(createFolder(dottedCollection));

    if (response?.payload?.folder) {
      const addedFolder = response.payload.folder;

      dispatch(
        pushFoldersIfExist({
          folders: [addedFolder],
          collectionId: dottedCollection.id,
        })
      );
      onCollectionClick({
        collection: dottedCollection,
        addToTabbar: false,
        updateRoute: false,
      });
      onFolderClick({ folder: addedFolder, collection: dottedCollection });
    }
  };

  const addApiToCollection = async () => {
    const response = await dispatch(
      createEntity({
        entityableType: 'Collection',
        entityable: dottedCollection,
      })
    );

    if (response?.payload?.entity) {
      const addedEntity = response.payload.entity;

      dispatch(
        pushCollectionEntitiesIfExist({
          entities: [addedEntity],
          collectionId: dottedCollection.id,
        })
      );
      onCollectionClick({
        collection: dottedCollection,
        addToTabbar: false,
        updateRoute: false,
      });
      onEntityClick({ entity: addedEntity, collection: dottedCollection });
    }
  };

  const handleMakeCollectionPublic = async () => {
    const res = await dispatch(
      makeCollectionPublic({ collectionId: dottedCollection.id })
    );

    if (res.payload?.message)
      openSnackbar({ message: res.payload.message, severity: 'success' });
    else if (res.payload?.error)
      openSnackbar({ message: res.payload.error, severity: 'error' });

    fetchCollections();
  };

  const handleMakeCollectionPrivate = async () => {
    const res = await dispatch(
      makeCollectionPrivate({ collectionId: dottedCollection.id })
    );

    if (res.payload?.message)
      openSnackbar({ message: res.payload.message, severity: 'success' });
    else if (res.payload?.error)
      openSnackbar({ message: res.payload.error, severity: 'error' });

    fetchCollections();
  };

  const handleOpenForkConfirm = () => {
    setOpenForkConfirm(true) &&
    setNewCollectionName(dottedCollection?.name + ' (Copy)');
  }
  
  const handleCloseForkConfirm = () => {
    setOpenForkConfirm(false);
    fetchCollections();
  };

  const handleAddDocConfirm = () => {
    fileInputRef2.current.click();
  };

  const handleOpenPublicConfirm = () => setOpenPublicConfirm(true);
  const handleClosePublicConfirm = () => setOpenPublicConfirm(false);
  const handleOpenPrivateConfirm = () => setOpenPrivateConfirm(true);
  const handleClosePrivateConfirm = () => setOpenPrivateConfirm(false);

  const handleMakeCollectionPublicConfirmed = () => {
    handleMakeCollectionPublic();
    handleClosePublicConfirm();
  };

  const handleMakeCollectionPrivateConfirmed = () => {
    handleMakeCollectionPrivate();
    handleClosePrivateConfirm();
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handlePostEntityDelete = (
    _entitySlug,
    entityableType,
    collectionId,
    folderId
  ) => {
    if (entitySlugRef.current === _entitySlug) {
      if (folderSlugRef.current) {
        window.history.pushState(
          null,
          '',
          `/collections/${collectionSlugRef.current}/folders/${folderSlugRef.current}`
        );
      } else {
        window.history.pushState(
          null,
          '',
          `/collections/${collectionSlugRef.current}`
        );
      }
    }

    if (entityableType === EntityableTypes.COLLECTION)
      dispatch(
        removeCollectionEntityBySlug({
          entitySlug: _entitySlug,
          collectionId,
        })
      );

    if (entityableType === EntityableTypes.FOLDER)
      dispatch(
        removeFolderEntityBySlug({
          entitySlug: _entitySlug,
          folderId,
        })
      );
  };

  const handlePostFolderDelete = (_folderSlug, collectionId) => {
    if (folderSlugRef.current === _folderSlug) {
      window.history.pushState(
        null,
        '',
        `/collections/${collectionSlugRef.current}`
      );
    }

    dispatch(removeFolderBySlug({ folderSlug: _folderSlug, collectionId }));
  };

  const handleDeleteConfirm = async () => {
    await dispatch(destroyCollection({ id: dottedCollection.id }));
    dispatch(removeTab(dottedCollection.id));
    handleDeleteDialogClose();

    if (collectionSlugRef.current === dottedCollection.slug) {
      window.history.replaceState(null, '', `/collections`);
    }

    setProjectCollections((prev) =>
      prev.filter((collection) => collection.id !== dottedCollection.id)
    );
  };

  const handleClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
    setMenuOpenNodeId(null);
  };

  const renameCollection = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setRenaming({
      isActive: true,
      collectionId: dottedCollection.id,
      newName: dottedCollection.name,
    });
  };

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && renaming.newName.trim()) {
      await rename();
    } else if (event.key === 'Escape') {
      setRenaming({ isActive: false, collectionId: null, newName: '' });
    }
  };

  const rename = async () => {
    if (renaming.collectionId && renaming.newName.trim()) {
      const body = { name: renaming.newName.trim(), id: renaming.collectionId };
      const result = await dispatch(updateCollection(body));

      if (result?.payload) {
        setRenaming({ isActive: false, collectionId: null, newName: '' });
        fetchCollections();
      } else {
        console.error('Rename failed');
      }
    }
  };

  const duplicateCollection = async () => {
    const response = await dispatch(
      duplicateResource({
        resource_id: dottedCollection.id,
        resource: 'collection',
      })
    );

    if (response?.payload) {
      const duplicatedCollection = response.payload;

      onCollectionClick({ collection: duplicatedCollection });
      setProjectCollections((prev) => [...prev, duplicatedCollection]);
      setSelectedNodeId(duplicatedCollection.id);
    }
  };

  const onEntitySlugUpdate = (entitySlug) => {
    triggerRouteUpdate(selectedState?.collection?.slug || collectionSlugRef.current, selectedState?.folder?.slug, entitySlug);
  }

  const triggerRouteUpdate = (
    collectionSlug = '',
    folderSlug = '',
    entitySlug = ''
  ) => {
    if (collectionSlug && folderSlug && entitySlug) {
      collectionSlugRef.current = collectionSlug;
      folderSlugRef.current = folderSlug;
      entitySlugRef.current = entitySlug;

      window.history.pushState(
        null,
        '',
        `/collections/${collectionSlug}/folders/${folderSlug}/${entitySlug}`
      );
      return;
    }

    if (collectionSlug && folderSlug) {
      collectionSlugRef.current = collectionSlug;
      folderSlugRef.current = folderSlug;
      entitySlugRef.current = '';

      window.history.pushState(
        null,
        '',
        `/collections/${collectionSlug}/folders/${folderSlug}`
      );
      return;
    }

    if (collectionSlug && entitySlug) {
      collectionSlugRef.current = collectionSlug;
      folderSlugRef.current = '';
      entitySlugRef.current = entitySlug;

      window.history.pushState(
        null,
        '',
        `/collections/${collectionSlug}/${entitySlug}`
      );
      return;
    }

    if (collectionSlug) {
      collectionSlugRef.current = collectionSlug;
      folderSlugRef.current = '';
      entitySlugRef.current = '';

      window.history.pushState(null, '', `/collections/${collectionSlug}`);
      return;
    }

    // TODO :: Handle default route
    console.error('Invalid route parameters');
  };

  const handleAddClick = (event) => {
    setAddMenuAnchorEl(event.currentTarget);
    setAddMenuOpen(true);
  };

  const handleAddMenuClose = () => {
    setAddMenuOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    resetUploadStatus();
  };

  const handleFileChange = async (event, importType) => {
    const file = event.target.files[0];

    if (!file) {
      alert('No file selected!');
      return;
    }

    if (!file.name.endsWith('.json')) {
      alert('Please select a JSON file.');
      return;
    }

    setDialogOpen(true);
    setAddMenuOpen(false);
    setUploadError('');

    uploadFile(file)
      .then(({ data, url }) => {
        dispatch(
          importCollection({ s3Url: url, projectId: project.id, importType: importType })
        ).then((res) => {
          setDialogOpen(false);
          if (res.payload?.message)
            openSnackbar({ message: res.payload.message, severity: 'success' });
          else if (res.payload?.error)
            openSnackbar({ message: res.payload.error, severity: 'error' });
        });
      })
      .catch((error) => {
        console.error('Upload failed:', error);
        setUploadError('Upload failed. Please try again.');
      })
      .finally(() => {
        if (importType === "postman") fileInputRefPostman.current.value = '';
        if (importType === "swagger") fileInputRefSwagger.current.value = '';
      });
  };

  const handleFileChangeForAddDoc = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert('No file selected!');
      return;
    }

    setDialogOpen(true);
    setUploadError('');

    uploadFile(file)
      .then(({ data, url }) => {
        dispatch(
          addDocForTestCaseRerun({
            s3Url: url,
            projectId: project.id,
            collectionId:
              fileUploadForResource === 'Collection' && selectedCollection?.id,
            folderId:
              fileUploadForResource === 'Folder' && selectedFolderForAddDoc?.id,
          })
        ).then((res) => {
          setDialogOpen(false);

          if (res.payload?.message)
            openSnackbar({ message: res.payload.message, severity: 'success' });
          else if (res.payload?.error)
            openSnackbar({ message: res.payload.error, severity: 'error' });
        });
      })
      .catch((error) => {
        console.error('Upload failed:', error);
        setUploadError('Upload failed. Please try again.');
      })
      .finally(() => {
        fileInputRef2.current.value = '';
      });
  };

  const renderAddMenu = (
    <Menu
      anchorEl={addMenuAnchorEl}
      open={addMenuOpen}
      onClose={handleAddMenuClose}
    >
      <MenuItem
        onClick={() => {
          onAddCollection();
          handleAddMenuClose();
        }}
      >
        Add a collection
      </MenuItem>
      <MenuItem
        onClick={() => {
          fileInputRefPostman.current.click();
          handleAddMenuClose();
        }}
      >
        Import a Postman collection
      </MenuItem>
      <MenuItem
        onClick={() => {
          fileInputRefSwagger.current.click();
          handleAddMenuClose();
        }}
      >
        Import a Swagger collection
      </MenuItem>
    </Menu>
  );

  const renderUploadDialog = () => (
    <Dialog open={isDialogOpen} onClose={handleDialogClose}>
      <DialogTitle>File Upload</DialogTitle>
      {uploadError && (
        <p style={{ color: 'red', padding: '0 24px' }}>{uploadError}</p>
      )}
      <LinearProgress variant="determinate" value={progress} />
    </Dialog>
  );

  const handleAddNewRequestToResource = () => {
    let data = {};

    if (entitySlugRef.current) {
      const entity =
        findEntityBySlug(collectionEntities, entitySlugRef.current) ||
        findEntityBySlugInFolders(folderEntities, entitySlugRef.current);

      let collection, folder;

      if (entity.entityable_type === EntityableTypes.COLLECTION) {
        collection = findCollectionByEntityId(
          collectionEntities,
          projectCollections,
          entity.id
        );
        data = {
          entityableType: EntityableTypes.COLLECTION,
          entityable: collection,
        };
      } else if (entity.entityable_type === EntityableTypes.FOLDER) {
        const folderId = findFolderByEntityId(folderEntities, entity.id);
        folder = findFolderById(collectionFolders, folderId);
        collection = findCollectionByFolderId(
          collectionFolders,
          projectCollections,
          folder.id
        );
        data = {
          entityableType: EntityableTypes.FOLDER,
          entityable: folder,
        };
      }

      dispatch(createEntity(data))
        .then((res) => {
          if (res?.payload?.entity) {
            const newTab = {
              name: 'New Request',
              id: res.payload.entity.id,
              resource: { ...res.payload.entity, folder, collection },
            };

            dispatch(addTab(newTab));

            if (data.entityableType === EntityableTypes.COLLECTION)
              fetchApis(collection.id);
            if (data.entityableType === EntityableTypes.FOLDER)
              fetchFoldersEntities([folder.id]);

            onEntityClick({ entity: res.payload.entity, folder, collection });
          }
        })
        .catch((error) => {
          console.error('Error adding new tab:', error);
        });
    } else if (folderSlugRef.current) {
      const folder = findFolderBySlug(collectionFolders, folderSlugRef.current);
      const collection = findCollectionBySlug(
        projectCollections,
        collectionSlugRef.current
      );

      data = {
        entityableType: 'Folder',
        entityable: folder,
      };

      dispatch(createEntity(data))
        .then((res) => {
          if (res?.payload?.entity) {
            const newTab = {
              name: 'New Request',
              id: res.payload.entity.id,
              resource: { ...res.payload.entity, folder, collection },
            };

            dispatch(addTab(newTab));
            fetchFoldersEntities([folder.id]);
            onEntityClick({ entity: res.payload.entity, folder, collection });
          }
        })
        .catch((error) => {
          console.error('Error adding new tab:', error);
        });
    } else if (collectionSlugRef.current) {
      const collection = findCollectionBySlug(
        projectCollections,
        collectionSlugRef.current
      );

      data = {
        entityableType: 'Collection',
        entityable: collection,
      };

      dispatch(createEntity(data))
        .then((res) => {
          if (res?.payload?.entity) {
            const newTab = {
              name: 'New Request',
              id: res.payload.entity.id,
              resource: { ...res.payload.entity, collection },
            };

            dispatch(addTab(newTab));
            fetchApis(collection.id);
            onEntityClick({ entity: res.payload.entity, collection });
          }
        })
        .catch((error) => {
          console.error('Error adding new tab:', error);
        });
    } else {
      openSnackbar({
        message: 'Please select a collection or folder to add a new request.',
        severity: 'error',
      });
    }
  };

  const onEntityClick = async ({
    entity,
    folder,
    collection,
    withLoading = true,
  }) => {
    if (withLoading) setIsLoadingScreen(true);

    setSelectedNodeId(entity.id);
    setCurrentScreen(ScreenTypes.API);

    const entityWithParents = { ...entity, folder, collection };
    const collectionApi =
      entity.entityable_type === EntityableTypes.COLLECTION ? entity : null;
    const folderApi =
      entity.entityable_type === EntityableTypes.FOLDER ? entity : null;
    await dispatch(setState({ collection, folder, collectionApi, folderApi }));

    await dispatch(fetchSummary(entity));
    await dispatch(setSidebarState(entity));

    if (entity?.entityable_type === EntityableTypes.COLLECTION) {
      triggerRouteUpdate(collection?.slug, '', entity?.slug);
      await dispatch(setApi(entity));
      updateTabbar(entityWithParents);
    } else if (entity?.entityable_type === EntityableTypes.FOLDER) {
      triggerRouteUpdate(
        collection?.slug || collectionSlugRef.current,
        folder?.slug,
        entity?.slug
      );
      await dispatch(setApi(entity));
      updateTabbar(entityWithParents);
    }

    if (withLoading)
      setTimeout(() => {
        setIsLoadingScreen(false);
      }, 500);
  };

  const onFolderClick = ({
    folder,
    collection,
    withLoading = true,
    updateRoute = true,
  }) => {
    if (withLoading) setIsLoadingScreen(true);

    setSelectedNodeId(folder.id);
    setCurrentScreen(ScreenTypes.FOLDER);
    dispatch(setState({ collection, folder }));

    dispatch(fetchSummary(folder));
    dispatch(setSidebarState(folder));

    handleFolderClick(folder.id);
    if (updateRoute) triggerRouteUpdate(collection?.slug, folder?.slug);

    dispatch(setFolder(folder));
    updateTabbar({ ...folder, collection });

    if (withLoading)
      setTimeout(() => {
        setIsLoadingScreen(false);
      }, 500);
  };

  const CollectionSidebarCmp = ({ collections }) => {
    const handleNodeToggle = (nodeId) => {
      if (!openNodes.includes(nodeId)) {
        setOpenNodes((prevOpenNodes) => [...prevOpenNodes, nodeId]);
      }
    };

    const handleTreeViewNodeToggle = (event, nodeIds) => {
      const expanding = nodeIds.filter((x) => !expandedNodeIds.includes(x));

      let newExpandedIds = [...expandedNodeIds];

      if (expanding.length > 0) {
        newExpandedIds = [...newExpandedIds, ...expanding];
      }

      if (event.target.tagName === 'svg' || event.target.tagName === 'path') {
        const collapsing = expandedNodeIds.filter((x) => !nodeIds.includes(x));

        if (collapsing.length > 0) {
          newExpandedIds = newExpandedIds.filter(
            (id) => !collapsing.includes(id)
          );
        }
      }

      setExpandedNodeIds(newExpandedIds);
    };

    const renderEntities = (entities, folder, collection) =>
      entities.map((entity) => (
        <TreeItem
          key={entity.id}
          nodeId={entity.id}
          onClick={() => onEntityClick({ entity, folder, collection })}
          label={
            <EntityComponent
              key={entity.id}
              entity={entity}
              folder={folder}
              collection={collection}
              refreshCollections={fetchCollections}
              handleDelete={handlePostEntityDelete}
              onEntityClick={onEntityClick}
            />
          }
        />
      ));

    const renderFolders = (folders, collection) =>
      folders.map((folder) => {
        const entities = folderEntities[folder.id];

        return (
          <TreeItem
            key={folder.id}
            nodeId={folder.id}
            onClick={() => onFolderClick({ folder, collection })}
            sx={{ '& .MuiTreeItem-iconContainer': { paddingLeft: '26px' } }}
            label={
              <FolderComponent
                key={folder.id}
                folder={folder}
                collection={collection}
                refreshCollections={fetchCollections}
                handleAddDocConfirm={handleAddDocConfirm}
                setSelectedFolderForAddDoc={setSelectedFolderForAddDoc}
                handleDelete={handlePostFolderDelete}
                onFolderClick={onFolderClick}
                onEntityClick={onEntityClick}
              />
            }
          >
            {Array.isArray(entities) ? (
              renderEntities(entities, folder, collection)
            ) : (
              <div></div>
            )}
          </TreeItem>
        );
      });

    const renderTree = (nodes=[]) =>
      nodes.map((node) => {
        const { id, name } = node;
        const folders = collectionFolders[id];
        const entities = collectionEntities[id];

        return (
          <TreeItem
            key={id}
            nodeId={id}
            sx={{
              '& .MuiCollapse-root': {
                marginLeft: 0,
              },
            }}
            // onClick={() => { setSelectedNodeId(node.id); handleNodeToggle(node.id); }}
            onClick={() => {
              if (selectedNodeId !== node.id) {
                setSelectedNodeId(node.id);
                onCollectionClick({ collection: node });
              }
            }}
            label={
              <Box
                display="flex"
                alignItems="center"
                onClick={() => onCollectionClick({ collection: node })}
                sx={{
                  '&:hover .more-icon': {
                    visibility: 'visible',
                  },
                  '.more-icon': {
                    visibility:
                      menuOpenNodeId === node.id ? 'visible' : 'hidden',
                  },
                  padding: "4px 0px",
                }}
              >
                {renaming.isActive && renaming.collectionId === node.id ? (
                  <input
                    ref={inputRef}
                    type="text"
                    value={renaming.newName}
                    onChange={(e) =>
                      setRenaming((prev) => ({
                        ...prev,
                        newName: e.target.value,
                      }))
                    }
                    onClick={(event) => event.stopPropagation()}
                    onKeyDown={handleKeyDown}
                    autoFocus
                    style={{
                      width: '100%',
                      border: '1px solid #e9e9e9',
                      borderRadius: '5px',
                      padding: '5px',
                      height: '25px',
                      fontSize: '12px',
                      fontFamily: "Inter, Sans-serif"
                    }}
                  />
                ) : (
                  <>
                    <Typography
                      variant="body2"
                      className="truncate-1-lines"
                      sx={{
                        flexGrow: 1,
                        fontSize: '13px',
                        ...(isGuestUser && {
                          marginTop: '3px',
                          marginBottom: '3px',
                        }),
                        fontWeight: "550",
                        fontFamily: "Inter, Sans-serif",
                        wordBreak: "break-all"
                      }}
                    >
                      {name}
                    </Typography>
                    {!isGuestUser && (
                      <IconButton
                        size="small"
                        onClick={(event) => onCollectionMenuClick(node, event)}
                        className="more-icon"
                      >
                        <MoreHorizIcon
                          fontSize="inherit"
                          ref={createRef(node.id)}
                        />
                      </IconButton>
                    )}
                  </>
                )}
              </Box>
            }
          >
            {Array.isArray(folders) &&
              folders.length > 0 &&
              renderFolders(folders, node)}
            {Array.isArray(entities) &&
              entities.length > 0 &&
              renderEntities(entities, null, node)}
          </TreeItem>
        );
      });

    return (
      <TreeView
        aria-label="collection tree"
        defaultCollapseIcon={<KeyboardArrowDown />}
        defaultExpandIcon={<KeyboardArrowRight />}
        defaultExpanded={expandedNodeIds}
        sx={{ flexGrow: 1, maxWidth: 400, bgcolor: 'background.paper', pb: "100px" }}
        expanded={expandedNodeIds}
        selected={selectedNodeId}
        onNodeToggle={handleTreeViewNodeToggle}
      >
        {renderTree(collections)}
      </TreeView>
    );
  };

  return (
    <>
      <Sidebar
        backgroundColor="#fff"
        width="250px"
        style={{
          // display: 'none',
          height: '100%',
          position: 'fixed',
          border: '1px solid #e9e9e9',
          paddingBottom: '60px',
          zIndex: 5
        }}
      >
        {/* Search and Add Collection Section */}
        {!isGuestUser && (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              borderBottom: '1px solid #e9e9e9',
              alignItems: 'center',
            }}
          >
            <Tooltip title="Add collection" placement="right-start">
              <IconButton
                onClick={handleAddClick}
                style={{ color: '#6241d4', marginRight: '8px' }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <input
              className="search-bar"
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search..."
              style={{
                width: '100%',
                fontSize: '13px',
                fontFamily: "Inter, Sans-serif"
              }}
            />

            <IconButton
              onClick={refetchCollectionList}
              style={{ color: '#6241d4' }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
        )}
        <CollectionSidebarCmp collections={filteredProjectCollections} />
      </Sidebar>

      <input
        type="file"
        ref={fileInputRefPostman}
        style={{ display: 'none' }}
        accept=".json"
        onChange={(e) => {handleFileChange(e, "postman")}}
      />
      <input
        type="file"
        ref={fileInputRefSwagger}
        style={{ display: 'none' }}
        accept=".json"
        onChange={(e) => {handleFileChange(e, "swagger")}}
      />

      <input
        type="file"
        ref={fileInputRef2}
        style={{ display: 'none' }}
        onChange={handleFileChangeForAddDoc}
      />
      {renderAddMenu}

      <Box sx={{ flex: 1, backgroundColor: '#fafafa', marginLeft: '250px' }}>
        {currentScreen !== ScreenTypes.NONE && (
          <CollectionNavbar
            state={selectedState}
            onCollectionClick={onCollectionClick}
            onFolderClick={onFolderClick}
            onEntityClick={onEntityClick}
            addNewRequestToResource={handleAddNewRequestToResource}
            setIsUserInitiatedTabChange={setIsUserInitiatedTabChange}
          />
        )}

        {currentScreen === ScreenTypes.COLLECTION &&
        tabs.length &&
        selectedState?.collection ? (
          <ProjectPage collection={selectedState?.collection} />
        ) : (
          <></>
        )}

        {currentScreen === ScreenTypes.FOLDER && tabs.length ? (
          <FolderScreen state={selectedState} folder={selectedState?.folder} />
        ) : (
          <></>
        )}

        {currentScreen === ScreenTypes.API && tabs.length ? (
          <ApiScreen
            key={selectedState?.collection?.name}
            state={selectedState}
            api={selectedApi}
            onEntitySlugUpdate={onEntitySlugUpdate}
          />
        ) : (
          <></>
        )}
      </Box>

      {renderUploadDialog()}
      <LoadingOverlay isLoading={isLoadingScreen} />

      {openForkConfirm && (
        <ForkDialog
          selectedCollection={dottedCollection}
          open={openForkConfirm}
          handleClose={handleCloseForkConfirm}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-icon-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{
          position: 'absolute',
          top: `${menuPosition.top}px`,
          left: `${menuPosition.left}px`,
        }}
      >
        {[
          { label: 'Add Folder', action: addFolderToCollection },
          { label: 'Add Request', action: addApiToCollection },
          'divider',
          dottedCollection?.public
            ? { label: 'Make Private', action: handleOpenPrivateConfirm }
            : { label: 'Make Public', action: handleOpenPublicConfirm },
          { label: 'Fork Collection', action: handleOpenForkConfirm },
          {
            label: 'Add doc and re-run test cases',
            action: handleAddDocConfirm,
          },
          'divider',
          { label: 'Duplicate', action: duplicateCollection },
          { label: 'Rename', action: (event) => renameCollection(event) },
          { label: 'Delete', action: handleDeleteDialogOpen, color: 'red' },
        ].map((item, index) =>
          item === 'divider' ? (
            <Divider key={index} />
          ) : (
            <MenuItem
              key={index}
              onClick={(event) => {
                if (item.label === 'Delete') event.stopPropagation();
                item.action(event);
                setMenuOpen(false);
              }}
              style={{
                fontSize: '12px',
                fontWeight: "500",
                fontFamily: "Inter, Sans-serif",
                color: item.color || 'inherit',
              }}
            >
              {item.label}
            </MenuItem>
          )
        )}
      </Menu>

      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
        title="Confirm Delete"
        content="Are you sure you want to delete?"
      />

      <Dialog open={openPublicConfirm} onClose={handleClosePublicConfirm}>
        <DialogTitle>{'Make this collection public?'}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}}>
            Making this collection public will allow others to view. Are you
            sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePublicConfirm}  sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}}>Cancel</Button>
          <Button onClick={handleMakeCollectionPublicConfirmed} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}} autoFocus>
            Make Public
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPrivateConfirm} onClose={handleClosePrivateConfirm}>
        <DialogTitle sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}}>{'Make this collection private?'}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}}>
            Making this collection private will allow others to view. Are you
            sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrivateConfirm} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}}>Cancel</Button>
          <Button onClick={handleMakeCollectionPrivateConfirmed} sx={{fontFamily: "Inter, Sans-serif", fontWeight: "500"}} autoFocus>
            Make Private
          </Button>
        </DialogActions>
      </Dialog>
      {openPublicForkModal && (<ForkModal isOpen={openPublicForkModal} organizations={organizations} onClose={handleForkPublicClose} onConfirm={handleForkPublicConfirm} collection={collectionToFork} />)}
    </>
  );
};

export default CollectionSidebar;
