import React, { useReducer } from 'react';
import moment from 'moment-timezone';
import { Typography, Box, TextField, Button, Grid, Autocomplete } from '@mui/material';
import coreUtils from '../../utils/coreUtils';

const OrganizationForm = ({ onSubmit, organization, actionType="new", isLoading }) => {
  const timeZones = moment.tz.names();
  const [state, setState] = useReducer(reducer, {
    companyName: organization?.name || "",
    companyUrl: organization?.url || "",
    companyLocation: organization?.location || "",
    host: organization?.host || "",
    errors: {},
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'companyName' && value.length > 50) {
      e.preventDefault();
      return;
    }
    setState({
      type: "SET_STATE",
      payload: { [name]: value },
    });
  };

  const onFormSubmit = async () => {
    var errors = {},
      error = false;

    if (coreUtils.isStringInvalidOrBlank(state.companyName)) {
      errors["companyName"] = "Please enter company name";
      error = true;
    }
    if (coreUtils.isStringInvalidOrBlank(state.companyUrl)) {
      errors["companyUrl"] = "Please enter url.";
      error = true;
    }
    if (coreUtils.isStringInvalidOrBlank(state.companyLocation)) {
      errors["companyLocation"] = "Please select time zone.";
      error = true;
    }

    setState({
      type: "SET_STATE",
      payload: {
        errors: errors,
      },
    });

    if (error) return;

    onSubmit(state);
  };

  return (
    <Box sx={{ my: 4 }}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography>Company Name<span style={{color: "grey"}}> (max chars: 50)</span></Typography>
          <TextField
            fullWidth
            size='small'
            variant="outlined"
            name="companyName"
            value={state.companyName}
            onChange={handleInputChange}
            error={!!state.errors.companyName}
            helperText={state.errors.companyName || ''}
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Company URL</Typography>
          <TextField
            fullWidth
            size='small'
            variant="outlined"
            name="companyUrl"
            value={state.companyUrl}
            onChange={handleInputChange}
            error={!!state.errors.companyUrl}
            helperText={state.errors.companyUrl || ''}
            disabled={actionType === "edit"}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Time Zone</Typography>
          <Autocomplete
            fullWidth
            disableClearable
            disablePortal
            size="small"
            value={state.companyLocation}
            onChange={(event, newValue) => {
              setState({
                type: "SET_STATE",
                payload: { companyLocation: newValue },
              });
            }}
            onClick={(event) => {
              console.log('clicked')
              console.log(event.path || event.composedPath())
            }}
            options={timeZones}
            disabled={actionType === "edit"}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label=""
                placeholder='Select time zone'
                error={!!state.errors.companyLocation}
                helperText={state.errors.companyLocation || ''}
                disabled={actionType === "edit"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Host</Typography>
          <TextField
            sx={{width: "100%"}} size='small' variant="outlined" name="host" value={state.host} onChange={handleInputChange}
            error={!!state.errors.host} helperText={state.errors.host || ''}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onFormSubmit}
            disabled={isLoading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganizationForm;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
