import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Fab
} from "@mui/material";
import {
  Send,
  Task,
  RestartAlt,
  SettingsSuggest,
  PersonSearch,
  Close,
  Handshake,
  BorderColor
} from "@mui/icons-material";
import qodexLogo from "../../assets/logos/logo_small.png";

import ActionPicker from "./ActionPicker";
import ChatContainer from "./ChatContainer";
import CollectionPicker from "./CollectionPicker";
import TagPicker from "./TagPicker";
import TaskPicker from "./TaskPicker";
import ProcessingShimmer from "./ProcessingShimmer";
import TextInput from "./TextInput";

import { fetchProjectCollections } from "../../redux-store/currentUserActions";
import { getTestScenarioTags } from "../../redux-store/testScenarioReducers/testScenarioActions";
import { createTasks } from "../../redux-store/globalReducers/globalActions";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import "../../styles/components/QodexBot/QodexBot.scss";

const actionItems = [
  {
    id: "onboardingGuide",
    display: "Onboarding guide",
    chatMessage: "Guide me to onboard",
    icon: <Handshake />,
    onSelect: "showOnboarding",
    hasActionSequence: true,
  },
  {
    id: "automationHelp",
    display: "Help me setup automation",
    chatMessage: "Help me setup automation",
    icon: <SettingsSuggest />,
    type: "link",
    url: ""
  },
  { 
    id: "getExpert",
    display: "Get me an expert",
    chatMessage: "Get me an expert",
    icon: <PersonSearch />,
    type: "link",
    url: ""
  },
  {
    id: "createTask",
    display: "Ask AI to generate test scenarios",
    chatMessage: "Ask AI to generate test scenarios",
    icon: <Task />,
    hasActionSequence: true,
    onComplete: "handleSaveTask",
    onSelect: "setSequenceDetail",
    onCompleteMessage: "Running tasks! Please wait."
  },
  {
    id: "createScenario",
    display: "Generate test scenarios with plain english",
    chatMessage: "Generate test scenarios with plain english",
    icon: <BorderColor />,
    hasActionSequence: true,
    onComplete: "handleCreateScenario",
    onSelect: "setSequenceDetail",
    onCompleteMessage: "Creating scenarios takes few seconds! Please wait."
  }
];
const resetActionItem = { id: "reset", display: "Start again", icon: <RestartAlt /> };

const QodexBot = ({projectId, onClose, showGuideModal, refreshProject}) => {
  const dispatch = useDispatch();
  const [chatItems, setChatItems] = useState([]);
  const [pickedAction, setPickedAction] = useState();
  const [errors, setErrors] = useState({});
  const { openSnackbar } = useSnackbar();
  const [selectedSequence, setSelectedSequence] = useState([]);
  const [sequenceStep, setSequenceStep] = useState(0);
  const [sequenceDetail, setSequenceDetail] = useState({});
  const [availableActions, setAvailableActions] = useState([...actionItems]);
  const [fabDisabled, setFabDisabled] = useState(false);
  const [query, setQuery] = useState("");
  const timeoutRef = useRef(null);
  
  const actionSequences = {
    createTask: [
      {
        id: "collections",
        title: "Select collections",
        chatMessage: "Selected collections",
        type: "autocomplete",
        selectionType: "multi",
        stateKey: "selectedCollections",
        component: (props) => (<CollectionPicker {...props} />),
        items: []
      },
      {
        id: "tasks",
        title: "Select tasks",
        chatMessage: "Selected tasks",
        type: "checkbox",
        selectionType: "multi",
        stateKey: "selectedTasks",
        component: (props) => (<TaskPicker {...props} />),
        items: []
      },
      {
        id: "tags",
        title: "Select tags",
        chatMessage: "Selected tags",
        type: "autocomplete",
        selectionType: "multi",
        stateKey: "selectedTags",
        component: (props) => (<TagPicker {...props}/>),
        items: []
      }
    ],
    createScenario: [
      {
        id: "collections",
        title: "Select collections",
        chatMessage: "Selected collections",
        type: "autocomplete",
        selectionType: "multi",
        stateKey: "selectedCollections",
        component: (props) => (<CollectionPicker {...props} />),
        items: []
      },
      {
        id: "text",
        title: "Write test scenario in plain english",
        chatMessage: "Scenario text",
        type: "text",
        selectionType: "text",
        stateKey: "scenarioText",
        component: (props) => (<TextInput {...props} />),
        items: []
      }
    ]
  }

  useEffect(() => {
    if (projectId) {
      getAllCollections();
      getAllTags();
    }
  }, [projectId])

  const showOnboarding = () => {
    showGuideModal(true);
  }

  const getAllCollections = async () => {
    await dispatch(fetchProjectCollections(projectId));
  };

  const getAllTags = async () => {
    await dispatch(getTestScenarioTags({ projectId: projectId }));
  };

  const getChatItem = (chatInfo) => {
    return {
      type: "text",
      items: null,
      readOnly: true,
      createdBy: "user",
      chatMessage: "",
      id: Date.now().toString(),
      ...chatInfo
    };
  }

  const removeLastChatItem = (removeProcessing=false) => {
    setChatItems(prevState => {
      if (!chatItems[chatItems.length - 1]?.readOnly) {
        return [...prevState.slice(0, prevState.length - 1)]
      } else if (prevState?.[prevState.length - 1]?.type === "process") {
        const newChatItem = getChatItem({chatMessage: chatItems?.[chatItems.length - 1]?.chatMessage, type: "text", element: null});
        return [...prevState.slice(0, prevState.length - 1), newChatItem];
      } else {
        return [...prevState.slice(0)];
      }
    });
  }

  const handleSendClick = async (e) => {
    e.preventDefault();
    const sequence = selectedSequence?.[sequenceStep];
    if (sequence && sequenceStep >= 0 && sequenceStep < selectedSequence.length) {
      if (!sequenceDetail?.[sequence?.stateKey] || sequenceDetail?.[sequence?.stateKey]?.length === 0) {
        // user presses button without any query/action
        // createChatItem(getChatItem({createdBy: "bot", chatMessage: "Selection required!", messageType: "error"}));
        return;
      }
      if (sequence?.selectionType === "multi")
        createChatItem(getChatItem({type: "chips", createdBy: "user", chatMessage: sequence?.chatMessage, items: sequenceDetail?.[sequence?.stateKey]}));
      else if (sequence?.selectionType === "text")
        createChatItem(getChatItem({type: "chips", createdBy: "user", chatMessage: sequence?.chatMessage, items: sequenceDetail?.[sequence?.stateKey]}));
      else
        createChatItem(getChatItem({type: "text", createdBy: "user", chatMessage: sequence?.chatMessage}));
      setSequenceStep(sequenceStep => (sequenceStep + 1));
      onSequenceNext(selectedSequence[sequenceStep + 1]);
      if (sequenceStep + 1 >= selectedSequence.length) {
        createChatItem(getChatItem({type: "process", createdBy: "bot", chatMessage: pickedAction?.onCompleteMessage, element: <ProcessingShimmer /> }), true);
        timeoutRef.current = ((action) => {
          setTimeout(() => {
            handleSequenceComplete(action?.onComplete, selectedSequence);
          }, 2000);
        })(pickedAction);
        return;
      }
    }
  }

  const handleActionClick = (actionItem) => {
    setPickedAction(actionItem);
    if (actionItem?.hasActionSequence) {
      if (actionItem?.id === 'createTask') {
        setSelectedSequence(prevState => actionSequences?.[actionItem.id]);
        onSequenceNext(actionSequences?.[actionItem.id]?.[0]);
      } else if (actionItem?.id === "onboardingGuide") {
        showOnboarding();
      } else if (actionItem?.id === "createScenario") {
        setSelectedSequence(prevState => actionSequences?.[actionItem.id]);
        onSequenceNext(actionSequences?.[actionItem.id]?.[0]);
      }
    }
  }

  const onSequenceNext = (selectedActionSequence) => {
    let actionSequence = selectedActionSequence;
    if (actionSequence) {
      const sequence = actionSequence;
      const onValueChange = (value) => {
        setSequenceDetail(prevState => {
          return {
            ...prevState,
            [sequence?.stateKey]: sequence?.type == "text" ? [{id: value, label: value}] : value
          }
        })
      }

      setAvailableActions([
        {type: "element", element: sequence.component({onValueChange: onValueChange, title: sequence?.title, initialValue: sequenceDetail?.[sequence?.stateKey]})},
        resetActionItem
      ]);
    } else {
      setAvailableActions([
        ...actionItems
      ]);
    }
  }

  const handleSequenceComplete = (type) => {
    if (type === "handleSaveTask") {
      handleSaveTask();
    } else if (type === "handleCreateScenario") {
      handleCreateScenario();
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && query && query.trim()) {
      event.preventDefault();
      const trimmedQuery = query.trim();
      createChatItem(getChatItem({chatMessage: trimmedQuery, type: "process", element: <ProcessingShimmer /> }));
      setQuery("");
    }
  }

  const handleCreateScenario = async () => {
    if (timeoutRef?.current) clearTimeout(timeoutRef.current);
    const response = await dispatch(
      createTasks({
        collectionIds: sequenceDetail?.selectedCollections?.map((c) => c.id),
        tags: [],
        taskIds: ["generate_single_integration_test"],
        scenarioText: sequenceDetail?.scenarioText?.[0]?.label
      })
    );
    if (response?.payload && !response?.error) {
      // openSnackbar({
      //   message: "Test scenario created successfully.",
      //   severity: "success",
      // });
      createChatItem(getChatItem({createdBy: "bot", chatMessage: "Test scenario created successfully."}));
      if (response?.payload?.test_scenario_id) {
        createChatItem(getChatItem({createdBy: "bot", type: "link", chatMessage: "Click here to check the new test scenario.", link: `/test-scenarios/${response.payload.test_scenario_id}`, newTab: true}))
      }
      refreshProject();
      onReset();
    } else {
      const errorMsg = response?.payload?.error;
      createChatItem(getChatItem({createdBy: "bot", status: "error", chatMessage: errorMsg || "Scenario creation failed! Retry."}));
      onReset();
    }
  }

  const handleSaveTask = async () => {
    // await createChatItem(getChatItem({createdBy: "bot", chatMessage: pickedAction?.onCompleteMessage || "Please wait, your request is in progress!"}));
    if (timeoutRef?.current) clearTimeout(timeoutRef.current);
    const response = await dispatch(
      createTasks({
        collectionIds: sequenceDetail?.selectedCollections?.map((c) => c.id),
        tags: sequenceDetail?.selectedTags?.map((t) => t.id),
        taskIds: sequenceDetail?.selectedTasks?.map((t) => t.id)
      })
    );
    if (response?.payload && !response?.error) {
      openSnackbar({
        message: "Task created successfully.",
        severity: "success",
      });
      createChatItem(getChatItem({createdBy: "bot", chatMessage: "Task creation triggered successfully."}));
      refreshProject();
      onReset();
    } else {
      const errorMsg = response?.payload?.error;
      createChatItem(getChatItem({createdBy: "bot", status: "error", chatMessage: errorMsg || "Task creation failed! Retry."}));
      onReset();
    }
  };

  const onReset = () => {
    setPickedAction(null);
    setSelectedSequence([]);
    setSequenceStep(0);
    setSequenceDetail({});
    setAvailableActions([...actionItems]);
  }

  const createChatItem = async (chatInfo, skipRemove=false) => {
    await removeLastChatItem();
    const chatItem = getChatItem({...chatInfo, time: Date.now()});
    setChatItems(prevState => [...prevState, {...chatItem}]);
  }

  return (
    <Box className="qodexBot">
      <Box>
        <Box className="heading">
          <img className="responsive-image centerContent" src={qodexLogo} alt="qodex Logo" />
          <span>QodeX Bot</span>
        </Box>
        <Box className="heading rightSide">
          <Close onClick={onClose} />
        </Box>
      </Box>
      <Box className="body">
        <ChatContainer chatItems={chatItems} />
        <ActionPicker onCreateChatItem={createChatItem} onActionClick={handleActionClick} actionItems={availableActions} onReset={onReset} />
      </Box>
      <Box className="footer">
        <input placeholder="Your message goes here..." onKeyDown={handleKeyDown} onChange={(event) => {setQuery(event.target.value)}} value={query} />
        <Fab disabled={fabDisabled} variant="outlined" onClick={handleSendClick}>
          <Send color="primary" />
        </Fab>
      </Box>
    </Box>
  );
};

export default QodexBot;
