import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateLoadError,
  setLoadingState,
  setSavingState,
  setTestPlanBuildList
} from './testPlanBuildSlice';

const createBuild = createAsyncThunk(
  'createBuild',
  async ({ testPlanId, environmentSetId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_plan_builds`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          test_plan_id: testPlanId,
        },
      });
      if (response.status === 200) {
        console.log(response?.data);
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getBuild = createAsyncThunk(
  'getBuild',
  async ({ testPlanBuildId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_plan_builds/${testPlanBuildId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        // console.log(response?.data);
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const getAllBuilds = createAsyncThunk(
  'getAllBuilds',
  async (
    { testPlanId, projectId, pageNumber, pageSize, filters },
    { dispatch, rejectWithValue }
  ) => {
    let paramsObj = {
      page: pageNumber,
      page_size: pageSize
    };
    if (testPlanId) paramsObj.test_plan_id = testPlanId;
    if (projectId) paramsObj.project_id = projectId;
    if (filters) paramsObj = {...paramsObj, ...filters};
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_plan_builds/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: paramsObj
      });
      if (response.status === 200) {
        await dispatch(setTestPlanBuildList(response?.data || []));
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

export { createBuild, getBuild, getAllBuilds };
