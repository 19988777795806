const coreUtils = {
  parseMinutes: (minutes = 0) => {
    const timeGapObj = { days: 0, hours: 0, minutes: 0 };
    if (
      minutes !== null &&
      minutes !== undefined &&
      !isNaN(minutes) &&
      minutes > 0
    ) {
      let mins = minutes * 1;
      timeGapObj.days = Math.floor(mins / (24 * 60));
      mins = mins % (24 * 60);
      timeGapObj.hours = Math.floor(mins / 60);
      mins = mins % 60;
      timeGapObj.minutes = Math.floor(mins);
    }
    return timeGapObj;
  },

  isStringInvalidOrBlank: (str) => {
    if (!str || !str?.trim()) {
      return true;
    }
    return false;
  },

  parseObjectToKeyValueArray: (obj) => {
    return Object.keys(obj).map((item) => ({
      var_name: item,
      var_value: obj[item],
      id: item,
    }));
  },

  getYear: (date, isFull=true) => {
    if (isFull)
      return date ? new Date(date).getFullYear() : new Date().getFullYear();
    return date ? new Date(date).getYear() : new Date().getYear();
  },

  getMonth: (date, isPadded=false) => {
    return date ? new Date(date).getMonth() + 1 : new Date().getMonth() + 1;
  },

  extractParamsFromUrl: (url) => {
    if (!url) {
      return [];
    }
    try {
      const indexOfQueryStart = url.indexOf("?");
      if (indexOfQueryStart !== -1) {
        const queryString = url.substring(indexOfQueryStart + 1);
        const params = new URLSearchParams(queryString);
        const extractedParams = Array.from(params.entries()).map(
          ([key, value]) => ({ name: key, value })
        );
        return extractedParams;
      }
      return [];
    } catch (error) {
      console.error("Error parsing URL parameters", url, error);
      return [];
    }
  },

  isDifferentJson: (json1={}, json2={}) => {
    if (!json1 && !json2) {
      return false;
    }
    if (!json1 || !json2) {
      return true;
    }
    return JSON.stringify(json1) !== JSON.stringify(json2);
  },

  isDifferentArray: (arr1=[], arr2=[], skipExtraFields=false) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
      return false;
    }
    const sortArr1 = [...arr1].map((item) => ({name: item?.name?.trim(), value: item?.value?.trim(), description: item?.description?.trim(), required: item?.required ? "true" : "false", datatype: item?.datatype?.trim()}));
    const sortArr2 = [...arr2].map((item) => ({name: item?.name?.trim(), value: item?.value?.trim(), description: item?.description?.trim(), required: item?.required ? "true" : "false", datatype: item?.datatype?.trim()}));
    sortArr1.sort((item1, item2) => item1?.name?.localeCompare(item2?.name));
    sortArr2.sort((item1, item2) => item1?.name?.localeCompare(item2?.name));
    return JSON.stringify(sortArr1) !== JSON.stringify(sortArr2);
  },

  isValidUrl: (url) => {
    const urlRegEx = new RegExp(/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi);
    return urlRegEx.test(url)
  }
};

export default coreUtils;
