import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    updateLoadError,
    setLoadingState,
    setSavingState
} from "./testRulesSlice";

const createTestRule = createAsyncThunk(
    "createTestRule",
    async ({testCaseSetId, expression, title}, { dispatch, rejectWithValue }) => {
        await dispatch(setLoadingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/api/v1/test_rules/`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                data: {
                    test_case_set_id: testCaseSetId,
                    expression: expression,
                    title: title
                }
            })
            if (response.status === 200) {
                await dispatch(setLoadingState(false));
                return response?.data;
            } else {
                await dispatch(setLoadingState(false));
                return rejectWithValue(response.data);
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
)

const getTestRule = createAsyncThunk(
    "getTestRule",
    async ({testRuleId}, {dispatch, rejectWithValue}) => {
        await dispatch(setLoadingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/api/v1/test_rules/?test_rule_id=${testRuleId}`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            });
            if (response.status === 200) {
                await dispatch(setLoadingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
            await dispatch(setLoadingState(false));
        }
    }
)

const getAllTestRules = createAsyncThunk(
    "getAllTestRules",
    async ({testCaseSetId}, {dispatch, rejectWithValue}) => {
        await dispatch(setLoadingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/api/v1/test_rules/?test_case_set_id=${testCaseSetId}`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            });
            if (response.status === 200) {
                await dispatch(setLoadingState(false));
                return response?.data?.test_rules;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
            await dispatch(setLoadingState(false));
        }
    }
)

const updateTestRule = createAsyncThunk(
    "updateTestRule",
    async ({testRuleId, data}, {dispatch, rejectWithValue}) => {
        await dispatch(setSavingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "PUT",
                url: `${process.env.REACT_APP_API_URL}/api/v1/test_rules/${testRuleId}`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                data: data
            });
            if (response.status === 200) {
                await dispatch(setSavingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
);

const deleteTestRule = createAsyncThunk(
    "deleteTestRule",
    async ({testRuleId}, {dispatch, rejectWithValue}) => {
        await dispatch(setSavingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "DELETE",
                url: `${process.env.REACT_APP_API_URL}/api/v1/test_rules/${testRuleId}`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            });
            if (response.status === 200) {
                await dispatch(setSavingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
);

const generateRuleWithAI = createAsyncThunk(
    "generateRuleWithAI",
    async ({ruleText, testCaseSetId}, {dispatch, rejectWithValue}) => {
        await dispatch(setLoadingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}//api/v1/test_rules/generate_by_ai`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                data: {
                    test_case_set_id: testCaseSetId,
                    rule_text: ruleText
                }
            });
            if (response.status === 200) {
                await dispatch(setLoadingState(false));
                return response?.data;
            }
        } catch (err) {
            await dispatch(setLoadingState(false));
            return rejectWithValue(err?.payload?.response);
        }
    }
)

const getAllSuggestedRules = createAsyncThunk(
    "getAllSuggestedRules",
    async ({testCaseSetId}, {dispatch, rejectWithValue}) => {
        await dispatch(setLoadingState(true));
        try {
            const authToken = localStorage.getItem("token");
            const response = await apiAgent({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/api/v1/suggested_test_rules/?test_case_set_id=${testCaseSetId}`,
                headers: {
                    Authorization: `Bearer ${authToken}`,
                }
            });
            if (response.status === 200) {
                await dispatch(setLoadingState(false));
                return response?.data?.test_rules;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
            await dispatch(setLoadingState(false));
        }
    }
)

export {
    createTestRule,
    getTestRule,
    getAllTestRules,
    updateTestRule,
    deleteTestRule,
    generateRuleWithAI,
    getAllSuggestedRules
}
