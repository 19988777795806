import React, { useState } from "react";
import { Box } from "@mui/material";
import TabsComponent from "./TabsComponent";
import APIDocs from "./APIDocs";
import EntityResponse from "./EntityResponse";
import EntityVariables from "./EntityVariables";

const TestCaseSetEntityResponse = ({ testCase, isExpanded }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabLabels = ["Response", "Variables", "Api Docs"];
  const handleTabChange = (event, tabIndex) => {
    setActiveTab(tabIndex);
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return <EntityVariables testCaseSetId={testCase?.id} />;
      case 2:
        return <APIDocs apiDocs={testCase?.entity?.summary?.overview} />;
      default:
        return <EntityResponse response={testCase?.test_case_set_response} />;
    }
  };
  return (
    <Box
      sx={{
        p: 2,
        bgcolor: "white",
        borderRadius: "8px",
        width: "calc(100% - 16px)",
        ml: "16px",
      }}
    >
      <TabsComponent
        tabLabels={tabLabels}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />
      {renderTabContent()}
    </Box>
  );
};

export default TestCaseSetEntityResponse;
