import React from 'react';
import {Autocomplete, TextField, Tooltip} from '@mui/material';

const EntitySelection = ({collections, entities, selectedEntity, selectedCollection, onCollectionChange, onEntityChange, isDisabled}) => {
  const methodColors = {
    GET: 'green',
    POST: '#ffb900',
    PUT: 'blue',
    PATCH: 'purple',
    DELETE: 'red',
    HEAD: 'green',
    OPTIONS: 'pink',
  }
  const getMethodColor = (method) => {
    let methodUpper = method?.toUpperCase();
    return methodColors?.[methodUpper] || "inherit";
  };
  return (
    <>
      <Autocomplete
        options={collections}
        getOptionLabel={option => option.name}
        renderOption={(props, option) => (
          <Tooltip title={option.name} placement="left-end">
            <div {...props}>
              <span>{option.name}</span>
            </div>
          </Tooltip>
        )}
        id="collection"
        value={selectedCollection}
        onChange={onCollectionChange}
        renderInput={(params) => (
          <TextField {...params} variant="standard" placeholder="Select collection" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={isDisabled}
        sx={{mr: '8px', width: "250px", display: "inline-block", fontSize: "14px", mt: 1}}
      />
      <Autocomplete
          options={entities}
          getOptionLabel={(option) => (`${option.request_type ? option.request_type + " - " : ""}${option.folder_name ? option.folder_name + " - " : ""}${option.name}`)}
          renderOption={(props, option) => (
            <Tooltip title={`${option.request_type ? option.request_type + " - " : ""}${option.folder_name ? option.folder_name + " - " : ""}${option.name}`} placement="left-end">
              <div {...props}>
                  <div className="truncate-1-lines">
                    <span style={{color: getMethodColor(option?.request_type), paddingRight: option?.request_type && "8px", fontWeight: 600}}>{option?.request_type}</span>
                    {option.folder_name && <span>{option?.folder_name} -&nbsp;</span>}
                    <span>{option.name}</span>
                  </div>
              </div>
            </Tooltip>
          )}
          value={selectedEntity}
          onChange={onEntityChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Select entity" 
              inputProps={{
                ...params.inputProps,
                value:
                  (selectedEntity || isDisabled) ? 
                  `${selectedEntity?.folder_name ? selectedEntity.folder_name + " - " : ""}${selectedEntity?.name ? selectedEntity.name : ""}` :
                  params.inputProps.value
              }}
            />
          )}
          disabled={isDisabled}
          sx={{width: "250px", display: "inline-block", fontSize: "14px", mt: 1}}
      />
    </>
  )
}

export default EntitySelection;
