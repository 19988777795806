import { useReducer } from 'react';
import {
  updateLoadingStage,
  updateCurrentUser,
  updateLoadError,
  updateCollections,
  updateFolders,
  updateApis,
  updateFolderEntities,
  updateCollectionEntities,
  updateCollectionApi,
  updateState,
  setSidebarState,
  setResourceSummary,
  cacheEntity,
  setFlatCollectionEntities,
  updateEntity as updateEntityAction,
  setOwners,
} from './currentUserSlice';
import { COOKIE_SETTINGS } from '../config/axiosConfig';
import { apiAgent } from '../config/axiosConfig';
import Cookies from 'universal-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';

// TODO :: Remove the following if there's no further use
export const fetchCurrentUser = createAsyncThunk(
  'fetchCurrentUser',
  async (_, { dispatch }) => {
    dispatch(updateLoadingStage(true));
    try {
      const storedUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(storedUser);

      dispatch(updateCurrentUser(parsedUser));
    } catch (err) {
      let timedout =
        err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: '', timedout }));
    }
  }
);

export const Signup = createAsyncThunk(
  'signupuser',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/users`,
        params: {
          user: {
            email: body.email,
            username: body.username,
            password: body.password,
            password_confirmation: body.password,
            token: body.token,
          },
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      let timedout =
        err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(
        updateLoadError({ error_message: err.response.data.errors, timedout })
      );
      return rejectWithValue(err.response ? err.response.data : err.message);
    }
  }
);

export const GoogleSignup = createAsyncThunk(
  'signupuser',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/google_signup`,
        params: {
          data: body.credential,
          invitation_token: body.invitationToken,
        },
      });

      if (res.status === 200) {
        localStorage.setItem('token', res.data.jwt);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        await dispatch(updateCurrentUser(res.data.user));

        return res.data;
      }
    } catch (err) {
      let timedout =
        err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(
        updateLoadError({ error_message: err.response.data.errors, timedout })
      );
    }
  }
);

export const Login = createAsyncThunk(
  'loginuser',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/users/sign_in`,
        params: {
          email: body.email,
          username: body.email,
          password: body.password,
        },
      });

      if (res.status === 200) {
        localStorage.setItem('token', res.data.jwt);
        localStorage.setItem('user', JSON.stringify(res.data.user));
        const cookies = new Cookies();
        cookies.set('api_key', res.data.jwt, {
          path: '/login',
          maxAge: COOKIE_SETTINGS['AUTH_DURATION'],
        });
        dispatch(updateCurrentUser(res.data.user));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return err.response.data.message;
    }
  }
);

export const Logout = createAsyncThunk(
  'logoutuser',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/users/sign_out`,
      });

      if (res.status === 200) {
        localStorage.clear();

        const cookies = new Cookies();
        cookies.remove('api_key', { path: '/login' });
        dispatch(updateCurrentUser(null));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return err.response.data.message;
    }
  }
);

export const confirmEmail = createAsyncThunk(
  'confirmEmail',
  async (token, { rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/users/confirm_email`,
        params: { token },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(
        err.response ? err.response.data : { error: err.message }
      );
    }
  }
);

export const inviteByEmail = createAsyncThunk(
  'inviteByEmail',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${body.projectId}/invite_by_email`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          email: body.email,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return err.response.data.message;
    }
  }
);

export const generateInviteLink = createAsyncThunk(
  'generateInviteLink',
  async (projectId, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${projectId}/generate_invite_link`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return err.response.data.message;
    }
  }
);

export const resendConfirmationEmail = createAsyncThunk(
  'resendConfirmationEmail',
  async (email, { dispatch, rejectWithValue }) => {
    try {
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/users/resend_email_confirmation`,
        params: {
          email: email,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        const [state, setState] = useReducer(reducer, {
          errors: {},
        });

        setState({
          type: 'SET_STATE',
          payload: {
            isLoading: false,
            resendError: res.data || 'Failed to resend confirmation email',
          },
        });

        return rejectWithValue(res.data);
      }
    } catch (err) {
      let timedout =
        err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(
        updateLoadError({ error_message: err.response.data.errors, timedout })
      );
    }
  }
);

export const createOrganisation = createAsyncThunk(
  'createOrganization',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/organisations`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          organisation: {
            name: body.companyName,
            url: body.companyUrl,
            location: body.companyLocation,
          },
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const createEntityTestCase = createAsyncThunk(
  'createEntityTestCase',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}/test_cases`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          test_case: body?.test_case,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const regenerateTestCaseFromQuery = createAsyncThunk(
  'regenerateTestCaseFromQuery',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}/test_cases/generate_test_cases`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        ...(body.testType === 'advanced' && {
          params: { test_type: body.testType },
        }),
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return rejectWithValue(err.response.data);
    }
  }
);

export const generateTestCaseFromQuery = createAsyncThunk(
  'generateTestCaseFromQuery',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}/test_cases/generate_test_case_from_query`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          query: body.query,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const getEntityTestCases = createAsyncThunk(
  'getEnityTestCases',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}/test_cases`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        ...(body.testType && { params: { test_type: body.testType } }),
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const getResponseApiTests = createAsyncThunk(
  'getResponseApiTests',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/responses/${body.response_id}/api_tests`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {},
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const sendApiCall = createAsyncThunk(
  'sendApiCall',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}/run`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {},
      });

      if (res.status === 200) {
        await dispatch(
          updateEntityAction({
            id: res.data?.response?.entity_id,
            response: res.data?.response,
          })
        );
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const createCollection = createAsyncThunk(
  'createCollection',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${body.id}/collections`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          collection: {
            name: 'New Collection',
          },
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const destroyCollection = createAsyncThunk(
  'destroyCollection',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/v1/collections/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {},
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

// TODO :: Complete the following thunk action once backend is ready
export const generateResourceOverview = createAsyncThunk(
  'generateResourceOverview',
  async (resource, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/${resource.type}/${resource.id}/generate_docs`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const destroyFolder = createAsyncThunk(
  'destroyFolder',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/v1/folders/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {},
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const destroyEntity = createAsyncThunk(
  'destroyEntity',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {},
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const duplicateResource = createAsyncThunk(
  'duplicateResource',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/${body.resource}/${body.resource_id}/duplicate`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {},
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchResourceRunners = createAsyncThunk(
  'fetchResourceRunners',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/${body?.resource}/${body.id}/runners`, //path to summary index
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {},
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchResourceRunsTriggered = createAsyncThunk(
  'fetchResourceRunsTriggered',
  async ({ page, perPage, resource }, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url:
          resource && resource.name && resource.id
            ? `${process.env.REACT_APP_API_URL}/api/v1/${resource.name}/${resource.id}/runs_triggered`
            : `${process.env.REACT_APP_API_URL}/api/v1/runs_triggered?project_id=${resource.project_id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          page,
          per_page: perPage,
          project_id: resource?.project_id,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchRunsTriggeredApis = createAsyncThunk(
  'fetchRunsTriggeredApis',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/runners/${body.id}/details`, //path to summary index
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {},
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchStats = createAsyncThunk(
  'fetchStats',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/stats`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          ...(_.collectionId && { collection_id: _.collectionId }),
          ...(_.folderId && { folder_id: _.folderId }),
          ...(_.entityId && { entity_id: _.entityId }),
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch {
      dispatch(updateLoadError({ error_message: 'Failed to fetch stats' }));
    }
  }
);

export const createRunSchedule = createAsyncThunk(
  'createRunSchedule',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${data.project_id}/run_schedules`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: data,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const getRunSchedules = createAsyncThunk(
  'getRunSchedules',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${data.project_id}/run_schedules`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: { resource_id: data.resource_id },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const deleteRunSchedule = createAsyncThunk(
  'deleteRunSchedule',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/v1/run_schedules/${data.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 204) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.log('err', err);
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const runResourceApis = createAsyncThunk(
  'runResourceApis',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/${body?.resource}/${body?.id}/run`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200 || res.status === 204) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchSummary = createAsyncThunk(
  'fetchSummary',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/summaries`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          resource_id: body.id,
        },
      });
      if (res.status === 200) {
        await dispatch(setResourceSummary(res.data.summary));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const updateResourceSummary = createAsyncThunk(
  'updateResourceSummary',
  async (body, { dispatch, rejectWithValue, history }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/summaries/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          summary: body?.summary,
        },
      });
      if (res.status === 200) {
        await dispatch(setResourceSummary(res.data.summary));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const updateCollection = createAsyncThunk(
  'updateCollection',
  async (body, { dispatch, rejectWithValue, history }) => {
    let collection = null;

    if (body?.authorization) {
      collection = {
        authorization: body?.authorization,
      };
    } else if (body?.variables) {
      collection = {
        variables: body?.variables,
      };
    } else if (body?.name) {
      collection = {
        name: body?.name,
      };
    } else if (body?.settings) {
      collection = {
        allowed_hosts: body?.settings?.allowedHosts,
        blocked_paths: body?.settings?.blockedPaths,
        pii_masking: body?.settings?.piiMaskingKeys,
        auto_documentation: body?.settings?.autoDocumentation,
        enable_monitoring: body?.settings?.enableMonitoring,
        runner_delay: body?.settings?.runnerDelay,
      };
    } else {
      collection = {
        overview: body?.overview,
      };
    }
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/collections/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          collection: collection,
        },
      });
      if (res.status === 200) {
        await dispatch(fetchProjectCollections(res.data.collection.project_id));
        await dispatch(updateState(res.data));
        await dispatch(setSidebarState(res.data.collection));

        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const updateEntity = createAsyncThunk(
  'updateEntity',
  async (body, { dispatch, rejectWithValue, history }) => {
    let entity = {};

    if (body?.name) {
      entity = {
        name: body?.name,
      };
    } else if (body?.entity) {
      entity = body?.entity;
    } else if (body?.testType) {
      entity = { test_type: body?.testType };
    } else {
      entity = {
        enable_variables: body?.enable_variables,
      };
    }
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          entity: entity,
        },
      });
      if (res.status === 200) {
        res.data.collection = body.collection;
        res.data.folder = body.folder;
        if (body.isCollectionApi) {
          res.data.collectionApi = res.data.entity;
        } else {
          res.data.folderApi = res.data.entity;
        }
        await dispatch(updateState(res.data));
        await dispatch(setSidebarState(res.data.entity));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const updateTestCase = createAsyncThunk(
  'updateTestCase',
  async (body, { dispatch, rejectWithValue, history }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_cases/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          test_case: body?.testCase,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const deleteTestCase = createAsyncThunk(
  'deleteTestCase',
  async (testCaseId, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_cases/${testCaseId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 200) {
        return { success: true, testCaseId };
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return rejectWithValue(err.response.data);
    }
  }
);

export const fixTestCase = createAsyncThunk(
  'fixTestCase',
  async (testCaseId, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_cases/${testCaseId}/fix`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (res.status === 200) {
        return { success: true, testCaseId };
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFolder = createAsyncThunk(
  'updateFolder',
  async (body, { dispatch, rejectWithValue }) => {
    let folder = {};

    if (body?.authorization) {
      folder = {
        authorization: body?.authorization,
      };
    } else if (body?.name) {
      folder = {
        name: body?.name,
      };
    }
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/folders/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          folder: folder,
        },
      });
      if (res.status === 200) {
        await dispatch(fetchCollectionFolders(res.data.folder.collection_id));

        res.data.collection = body.collection;
        await dispatch(updateState(res.data));
        await dispatch(setSidebarState(res.data.folder));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const createFolder = createAsyncThunk(
  'createFolder',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/collections/${body.id}/folders`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          folder: {
            name: 'New Folder',
          },
        },
      });
      if (res.status === 200) {
        await dispatch(fetchCollectionFolders(res.data.folder.collection_id));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);
export const updateApi = createAsyncThunk(
  'updateApi',
  async (body, { dispatch, rejectWithValue }) => {
    let entity = null;
    if (body?.authorization) {
      entity = {
        authorization: body?.authorization,
      };
    } else if (body?.params) {
      entity = {
        params: body?.params,
      };
    } else if (body?.headers) {
      entity = {
        headers: body?.headers,
      };
    } else {
      entity = {
        body: body?.body,
      };
    }
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          entity: entity,
        },
      });
      if (res.status === 200) {
        await dispatch(fetchCollectionApis(res.data.entity.entityable_id));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const createProject = createAsyncThunk(
  'createProject',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/organisations/${body.organisationId}/projects`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          project: {
            name: body.projectName,
            url: body.projectUrl,
          },
        },
      });

      if (res.status === 200) {
        localStorage.setItem('project', JSON.stringify(res.data));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err?.payload?.response);
      // dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const getOrganisations = createAsyncThunk(
  'getOrganisations',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/organisations`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 200) {
        localStorage.setItem('project', JSON.stringify(res.data.project));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const getPastResponses = createAsyncThunk(
  'getPastResponses',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.entityId}/responses`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          page: body.page,
          per_page: body.perPage,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchProjectMembers = createAsyncThunk(
  'fetchProjectMembers',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const projectId = body;
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${projectId}/members`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const deleteProjectMember = createAsyncThunk(
  'deleteProjectMember',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${_.projectId}/delete_member?member_id=${_.memberId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchProjectCollections = createAsyncThunk(
  'fetchProjectCollections',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const projectId = body;
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${projectId}/collections`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        await dispatch(updateCollections(res.data.collections));
        return res;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchCollectionFolders = createAsyncThunk(
  'fetchCollectionFolders',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const collectionId = body;
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/collections/${collectionId}/folders`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        await dispatch(updateFolders({ data: res.data, collectionId }));

        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchCollectionApis = createAsyncThunk(
  'fetchCollectionApis',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const collectionId = body;
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities`,
        params: {
          entityable_id: collectionId,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 200) {
        await dispatch(
          updateCollectionEntities({ data: res.data, collectionId })
        );
        return res;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchFoldersApis = createAsyncThunk(
  'fetchFoldersApis',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const folderIds = body;
      const authToken = localStorage.getItem('token');

      return Promise.all(
        folderIds.map((folderId) =>
          apiAgent({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/api/v1/entities?entityable_id=${folderId}`,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
        )
      )
        .then((response) => {
          const updateFolderApis = async (folderId, entities) => {
            await dispatch(updateFolderEntities({ folderId, entities }));
          };
          folderIds.map((folderId, index) => {
            updateFolderApis(folderId, response[index]?.data?.entities || []);
          });
          // console.log(response);
        })
        .catch((error) => {
          dispatch(updateLoadError({ error_message: error.message }));
        });
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchVariables = createAsyncThunk(
  'fetchVariables',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variables`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          resource_id: body.id,
        },
      });
      if (res.status === 200) {
        return res.data.variables;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const createVariable = createAsyncThunk(
  'fetchVariables',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variables`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          variable: {
            resource_id: body.resource_id,
            resource_type: body.resource_type,
            var_value: body.var_value,
            var_name: body.var_name,
          },
        },
      });
      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const bulkCreateVariables = createAsyncThunk(
  'variables/bulkCreate',
  async (variables, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variables/bulk_create`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          variables: variables,
        },
      });
      if (res.status === 200) {
        return res.data.variables;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(
        updateLoadError({
          error_message:
            err.response?.data?.message || 'Unknown error occurred',
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const bulkUpdateVariables = createAsyncThunk(
  'variables/bulkUpdate',
  async (variables, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variables/bulk_update`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          variables: variables,
          ids: variables.map((variable) => variable.id),
        },
      });
      if (res.status === 200) {
        return res.data.variables;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(
        updateLoadError({
          error_message:
            err.response?.data?.message || 'Unknown error occurred',
        })
      );
      return rejectWithValue(err);
    }
  }
);

export const deleteVariable = createAsyncThunk(
  'deleteVariable',
  async (variableId, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/api/v1/variables/${variableId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const updateRequest = createAsyncThunk(
  'updateRequest',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/request/${body?.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          request: body?.request,
        },
      });
      if (res.status === 200) {
        await dispatch(
          updateEntityAction({ id: res.data?.entity_id, request: res.data })
        );
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const getEntity = createAsyncThunk(
  'getEntity',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const resource = body;
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (res.status === 200) {
        await dispatch(cacheEntity({ id: body.id, data: res.data }));
        // TODO :: Update local state apis record when any entity is updated
        // await dispatch(updateCollectionApi({ id: body.id, data: res.data }));
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const createEntity = createAsyncThunk(
  'createEntity',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities`,
        // url: `${process.env.REACT_APP_API_URL}/api/v1/request/${body.request.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          entity: {
            entityable_id: body.entityable.id,
            entityable_type: body.entityableType,
            name: 'New Request',
          },
        },
      });
      if (res.status === 200) {
        return {entity: res.data};
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      // let timedout = err.code === 'ECONNABORTED' || err.response?.status === 500;
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  'updateUserProfile',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');

      const res = await apiAgent({
        method: 'patch',
        url: `${process.env.REACT_APP_API_URL}/api/v1/users/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          user: {
            name: body.name,
            // username: body.username,
            // email: body.email,
            notification_settings: body.notificationSettings,
          },
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const fetchFlatCollectionEntities = createAsyncThunk(
  'fetchFlatCollectionEntities',
  async (collectionId, { dispatch, rejectWithValue }) => {
    await dispatch(updateLoadingStage(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/entities/flat?collection_id=${collectionId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        await dispatch(
          setFlatCollectionEntities({
            entities: response?.data?.entities,
            collectionId: collectionId,
          })
        );
        await dispatch(updateLoadingStage(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const getOwners = createAsyncThunk(
  'getOwners',
  async ({ projectId }, { dispatch }) => {
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${projectId}/users`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        await dispatch(setOwners(response.data.users));
        return response.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'updateOrganization',
  async (body, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/api/v1/organisations/${body.id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          name: body.name,
          host: body.host
        },
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const updateProject = createAsyncThunk(
  'updateProject',
  async ({id, ...body}, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${id}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        data: body,
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      return rejectWithValue(err?.payload?.response);
      // dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export const getProject = createAsyncThunk(
  'getProject',
  async ({projectId}, { dispatch, rejectWithValue }) => {
    try {
      const authToken = localStorage.getItem('token');
      const res = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/projects/${projectId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        }
      });

      if (res.status === 200) {
        return res.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATE': {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
};
