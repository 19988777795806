import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateLoadError,
  setLoadingState,
  setSavingState,
  updateTestSuitesList,
  setTestSuiteTags,
  updateSelectedTestSuiteDetail
} from './testSuiteSlice';
import {
  TEST_SUITES_PAGE_SIZE,
  TEST_SCENARIOS_PAGE_SIZE,
} from '../../config/constants';

const createTestSuite = createAsyncThunk(
  'createTestSuite',
  async ({ projectId, title }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_suites/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          project_id: projectId,
          title: title,
        },
      });
      if (response.status === 200) {
        console.log(response?.data);
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getTestSuite = createAsyncThunk(
  'getTestSuite',
  async ({ testSuiteId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_suites/${testSuiteId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200) {
        console.log(response?.data);
        await dispatch(
          updateSelectedTestSuiteDetail({
            testSuiteDetail: response?.data,
          })
        );
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const getAllTestSuites = createAsyncThunk(
  'getAllTestSuites',
  async (
    {
      projectId,
      pageNumber,
      searchText = null,
      pageSize = TEST_SUITES_PAGE_SIZE,
      tags = [],
      statuses = [],
      environmentSetId = null,
      buildStatus = null,
      ownerIds = [],
    },
    { dispatch }
  ) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_suites`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          project_id: projectId,
          page: pageNumber,
          page_size: pageSize,
          search_text: searchText,
          environment_set_id: environmentSetId,
          build_status: buildStatus,
          owner_ids: ownerIds,
          tags,
          statuses,
        },
      });
      if (response.status === 200) {
        await dispatch(
          updateTestSuitesList({
            testSuitesList: response?.data?.test_suites,
            totalPages: response?.data?.total_pages,
            total: response?.data?.total,
          })
        );
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const updateTestSuite = createAsyncThunk(
  'updateTestSuite',
  async ({ testSuiteId, data }, { dispatch }) => {
    await dispatch(setSavingState(true));
    try {
      const authToken = localStorage.getItem('token');

      const response = await apiAgent({
        method: 'PUT',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_suites/${testSuiteId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data,
      });
      if (response.status === 200) {
        await dispatch(
          updateSelectedTestSuiteDetail({
            testSuiteDetail: response?.data,
          })
        );
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const deleteTestSuite = createAsyncThunk(
  'deleteTestSuite',
  async ({ testSuiteId }, { dispatch }) => {
    await dispatch(setSavingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_suites/${testSuiteId}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 204) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getTestSuiteTags = createAsyncThunk(
  'getTestSuiteTags',
  async ({ projectId }, { dispatch }) => {
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_suites/tags`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          project_id: projectId,
        },
      });
      if (response.status === 200) {
        await dispatch(setTestSuiteTags(response.data.tags));
        return response.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getTestScenariosForSuite = createAsyncThunk(
  'getTestScenariosForSuite',
  async (
    { projectId, testSuiteId, pageNumber, pageSize = TEST_SCENARIOS_PAGE_SIZE },
    { dispatch }
  ) => {
    await dispatch(setLoadingState(true));
    try {
      const authToken = localStorage.getItem('token');
      const response = await apiAgent({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/v1/test_scenarios`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          page: pageNumber,
          page_size: pageSize,
          project_id: projectId,
          test_suite_id: testSuiteId,
        },
      });

      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

export {
  createTestSuite,
  getTestSuite,
  getAllTestSuites,
  updateTestSuite,
  deleteTestSuite,
  getTestSuiteTags,
  getTestScenariosForSuite,
};
