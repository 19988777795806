import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import {Box} from "@mui/material";
import OrganizationForm from './OrganizationForm';

import { updateOrganization } from '../../redux-store/currentUserActions'; 
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const OrganizationSettings = ({organization, onUpdate}) => {
  const dispatch = useDispatch();
  const {openSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data={}) => {
    setIsLoading(true);
    const response = await dispatch(updateOrganization({id: organization.id, name: data.companyName, host: data.host}))
    if (response?.payload) {
      openSnackbar({ message: "Organization updated successfully!", severity: 'success' });
      onUpdate();
      setIsLoading(false);
    } else {
      openSnackbar({ message: "Organization updation failed!", severity: 'error' });
      setIsLoading(false);
    }
  }

  return (
    <Box>
      <OrganizationForm organization={organization} onSubmit={handleSubmit} isLoading={isLoading} actionType="edit" />
    </Box>
  )
}

export default OrganizationSettings;
