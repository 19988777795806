import React, {useState} from 'react';
import {TextField, Box, Paper} from "@mui/material";

const TextInput = ({onValueChange, title}) => {
  const [text, setText] = useState("");
  const onChange = (e) => {
    setText(e.target.value);
    onValueChange(e.target.value);
  }
  return (
    <Box sx={{ my: 1, width: "100%" }}>
      <h6 style={{ fontWeight: "bold", textAlign: "left" }}>{title}</h6>
      <Paper sx={{ width: "100%",maxHeight: "175px", overflow: "auto", boxShadow: "none", background: "#f5f5f5" }}>
        <textarea  style={{margin: "8px 0px 0px", width: "100%", resize: "none", height: "100px",outlineColor: "#6241d4"}} value={text} onChange={onChange} />
      </Paper>
    </Box>
  )
}

export default TextInput;
