import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";

import coreUtils from "../../utils/coreUtils";
import "../../styles/components/Common/KeyValueTable.scss";

const rawItem = {
  id: Date.now().toString(),
  isNew: true,
  isEdit: false,
  var_name: "",
  var_value: "",
};

const inputProps = {
  classes: { notchedOutline: "no-border" },
  className: "custom-textfield",
};

const KeyValueTable = function ({
  allItems = [],
  onCreateItem,
  onUpdateItem,
  onGetNewItem,
  onDeleteItem,
  onValidate,
  isReadOnly = false,
  itemName = "item",
  sxTableContainer= {},
  sxCell={},
  json=false
}) {
  const [items, setItems] = useState(allItems);
  const [mutableItems, setMutableItems] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [newItem, setNewItem] = useState(
    onGetNewItem ? onGetNewItem() : { ...rawItem }
  );
  const [editingItemId, setEditingItemId] = useState("");
  const [deletingItemId, setDeletingItemId] = useState("");

  const cellStyle = { textAlign: "center", padding: "3px", margin: 0, ...sxCell };
  const centerAlignCellStyle = { textAlign: "center", padding: 0, margin: 0, ...sxCell };
  const textFieldStyle = {
    height: "27px",
    "& .MuiInputBase-input": { padding: "5px 10px", fontSize: "12px", ...sxCell },
  };

  useEffect(() => {
    setItems(allItems);
    setEditingItemId("");
  }, [allItems]);

  useEffect(() => {
    if (!isReadOnly) {
      updateMutableItems();
      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [allItems, editingItemId])

  const handleOutsideClick = () => {
    updateMutableItems();
    setEditingItemId("");
  };

  const updateMutableItems = () => {
    setMutableItems(
      items.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: { ...curr },
        }),
        {}
      )
    );
  }

  const getNewItem = () => {
    if (onGetNewItem) return onGetNewItem();
    return { ...rawItem };
  };

  const validateItem = useCallback(
    (var_name, var_value, isNew) => {
      if (isNew && coreUtils.isStringInvalidOrBlank(var_name)) {
        setErrorMsg("Please enter a valid key");
        return false;
      }
      if (coreUtils.isStringInvalidOrBlank(var_value)) {
        setErrorMsg("Please enter a valid value");
        return false;
      }
      if (isNew) {
        const matchingKey = items.find((item) => item.var_name === var_name);
        if (matchingKey) {
          setErrorMsg(`Key name collides with other ${itemName} key`);
          return false;
        }
      }

      return true;
    },
    [items]
  );

  const createItem = useCallback(
    (item) => {
      if (!validateItem(item.var_name, item.var_value, true)) return false;
      const data = {
        var_name: item.var_name,
        var_value: item.var_value,
      };
      onCreateItem(data);
      setNewItem(getNewItem());
    },
    [validateItem]
  );

  const saveNewItem = useCallback(
    (item) => {
      if (item.var_name && item.var_value) createItem(item);
    },
    [createItem]
  );

  const updateItemValue = useCallback(
    (id) => {
      const updatedValue = mutableItems[id]?.var_value || "";
      if (!validateItem(mutableItems[id]?.var_name, updatedValue)) return false;
      if (updatedValue) {
        onUpdateItem({
          var_value: updatedValue,
          id: id,
        });
      }
    },
    [mutableItems, setItems]
  );

  const handleNewItemChange = useCallback(
    (var_name, var_value) => {
      setNewItem((prev) => ({ ...prev, [var_name]: var_value }));
      setErrorMsg("");
    },
    [setNewItem, setErrorMsg]
  );

  const handleChange = (e, id) => {
    setMutableItems((prev) => ({
      ...prev,
      [id]: { ...prev[id], var_value: e.target.value },
    }));
  };

  const deleteItem = () => {
    onDeleteItem(deletingItemId);
    setDeletingItemId("");
  };

  const handleKeyDown = (e, item, isNew) => {
    if (e.key === "Enter") {
      if (isNew) {
        saveNewItem(item);
      } else {
        updateItemValue(item);
      }
    }
  };

  const renderDeleteDialog = () => {
    return (
      <Dialog
        maxWidth="xs"
        onClose={() => setDeletingItemId("")}
        open={Boolean(deletingItemId)}
      >
        <DialogTitle>Delete {itemName}</DialogTitle>
        <div style={{ minWidth: "300px", padding: "0px 18px 14px 18px" }}>
          Are you sure you want to delete this {itemName}? This cannot be
          undone.
        </div>
        <DialogActions>
          <Button variant="outlined" onClick={() => setDeletingItemId("")}>
            Cancel
          </Button>
          <Button variant="outlined" color="error" onClick={() => deleteItem()}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderNewItem = () => {
    return (
      <TableRow>
        <TableCell sx={cellStyle}></TableCell>
        <TableCell sx={centerAlignCellStyle}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="Key"
            value={newItem.var_name}
            onChange={(e) => handleNewItemChange("var_name", e.target.value)}
            onKeyDown={(e) => {
              handleKeyDown(e, newItem, true);
            }}
            InputProps={inputProps}
            sx={textFieldStyle}
          />
        </TableCell>
        <TableCell sx={centerAlignCellStyle}>
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            placeholder="Value"
            value={newItem.var_value}
            onChange={(e) => handleNewItemChange("var_value", e.target.value)}
            onKeyDown={(e) => {
              handleKeyDown(e, newItem, true);
            }}
            InputProps={inputProps}
            sx={textFieldStyle}
          />
        </TableCell>
        <TableCell sx={centerAlignCellStyle}></TableCell>
      </TableRow>
    );
  };

  const getJsonFormatted = (value) => {
    try {
      if (!value) {
        return JSON.stringify({});
      } else if (typeof value === "string") {
        const jsonParsed = JSON.parse(value);
        return JSON.stringify(jsonParsed, null, 2);
      } else {
        return JSON.stringify(value, null, 2);
      }
    } catch (e) {
      return value;
    }
  }

  if (items.length === 0 && isReadOnly) {
    return <div>No {itemName}s found!</div>
  }

  return (
    <Box sx={{ py: 2 }} className="keyValueTable">
      <TableContainer sx={{ backgroundColor: "#fff", ...sxTableContainer }}>
        <Table size="small" aria-label="simple" sx={{ border: "1px solid" }}>
          <TableHead>
            <TableRow sx={{ alignItems: "center", height: "1vh" }}>
              <TableCell sx={cellStyle}>S.N.</TableCell>
              <TableCell sx={cellStyle}>Key</TableCell>
              <TableCell sx={cellStyle}>Value</TableCell>
              {!isReadOnly && (
                <TableCell sx={{ width: "5%", ...cellStyle }}></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ id, var_name, var_value }, index) => (
              <TableRow key={id}>
                <TableCell sx={cellStyle}>{index + 1}</TableCell>
                <TableCell
                  sx={{ textAlign: "left", margin: 0, padding: "5px 10px", ...sxCell }}
                  className="disabled"
                >
                  {var_name}
                </TableCell>
                {!isReadOnly && (
                  <TableCell sx={centerAlignCellStyle}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="Value"
                      onClick={() => setEditingItemId(id)}
                      value={
                        editingItemId === id ? mutableItems[id]?.var_value : var_value
                      }
                      onChange={(e) => {
                        handleChange(e, id);
                      }}
                      onKeyDown={(e) => {
                        handleKeyDown(e, id);
                      }}
                      InputProps={inputProps}
                      sx={textFieldStyle}
                    />
                  </TableCell>
                )}
                {isReadOnly && (
                  <TableCell
                    sx={{ textAlign: "left", margin: 0, padding: "5px 10px", ...sxCell }}
                  >
                    {json ? <pre>{getJsonFormatted(var_value)}</pre> : var_value}
                  </TableCell>
                )}
                {!isReadOnly && (
                  <TableCell sx={centerAlignCellStyle}>
                    <IconButton
                      size="small"
                      aria-label="Delete item"
                      onClick={() => setDeletingItemId(id)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {!isReadOnly && renderNewItem()}
          </TableBody>
        </Table>
      </TableContainer>
      {errorMsg && <p style={{ color: "#bb0101", marginTop: "8px" }}>{errorMsg}</p>}
      {!isReadOnly && renderDeleteDialog()}
    </Box>
  );
};

export default KeyValueTable;
