import React, { useReducer, useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Box, TextField, Button, Grid } from '@mui/material';

import { updateProject, getProject } from '../../redux-store/currentUserActions';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import coreUtils from '../../utils/coreUtils';

const ProjectSettings = ({ project, onUpdate }) => {
  const dispatch = useDispatch();
  const [state, setState] = useReducer(reducer, {
    name: project?.name || "",
    url: project?.url || "",
    errors: {},
  });
  const {openSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const initialMount = useRef(true);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      fetchProject();
    } else {
      setInitialState(project);
    }
  }, []);

  const fetchProject = async () => {
    const response = await dispatch(getProject({projectId: project?.id}));
    if (response?.payload) {
      setInitialState(response.payload);
    }
  }

  const setInitialState = (data) => {
    setState({
      type: "SET_STATE",
      payload: {
        name: data?.name || "",
        url: data?.url || ""
      }
    })
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' && value.length > 50) {
      e.preventDefault();
      return;
    }
    setState({
      type: "SET_STATE",
      payload: { [name]: value },
    });
  };

  const validate = () => {
    let errors = {}, error = false;
    if (coreUtils.isStringInvalidOrBlank(state.name)) { errors["name"] = "Please enter name"; error = true; }
    if (coreUtils.isStringInvalidOrBlank(state.url)) { errors["url"] = "Please enter url."; error = true; }
    setState({
      type: "SET_STATE",
      payload: {
        errors: errors,
      },
    });

    return error;
  }

  const onFormSubmit = async (data) => {
    let error = validate();
    if (error) return;
    setIsLoading(true);
    const response = await dispatch(updateProject({
      id: project?.id,
      name: state?.name?.trim(),
      url: state?.url?.trim()
    }));
    if (response?.payload && !response?.error) {
      if (response?.payload?.id) {
        openSnackbar({ message: "Project updated successfully!", severity: 'success' });
        onUpdate({...response.payload, total_credits: project?.total_credits} || {});
      } else {
        openSnackbar({ message: "Something went wrong, please try again!", severity: 'error' });
      }
      setIsLoading(false);
    } else {
      openSnackbar({ message: response?.payload?.error || "Project updation failed!", severity: 'error' });
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ my: 4 }}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography>Name<span style={{color: "grey"}}> (max chars: 50)</span></Typography>
          <TextField
            fullWidth size='small' variant="outlined" name="name" value={state.name} onChange={handleInputChange}
            error={!!state.errors.name} helperText={state.errors.name || ''} maxLength={50}
          />
        </Grid>
        <Grid item xs={12} sx={{mb: 1}}>
          <Typography>URL</Typography>
          <TextField
            fullWidth size='small' variant="outlined" name="url" value={state.url} onChange={handleInputChange}
            error={!!state.errors.url} helperText={state.errors.url || ''}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onFormSubmit}
            disabled={isLoading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectSettings;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
