import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Link } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ApiComponent from "./ApiComponent";
import ResponseComponent from "../Collections/ResponseComponent";
import OverviewComponent from '../Collections/OverviewComponent.jsx';
import CollectedSamplesTable from '../Samples/CollectedSamplesTable';
import ApiOptions from './ApiOptions';
import { AppProvider } from '../../AppContext';
import { useDispatch, useSelector } from "react-redux";
import { getEntity, fetchStats, fetchSummary } from "../../redux-store/currentUserActions";
import MetricsComponent from '../Collections/MetricsComponent';
import Audit from '../Collections/Audit';
import { fetchAuditData, fetchAuditDataDetails } from '../../redux-store/collectionActions';

const ApiScreen = ({ state, api, onEntitySlugUpdate }) => {
  const dispatch = useDispatch();
  const selectedSidebarState = useSelector((state) => state.user.selectedSidebarState);
  const [apiOverview, setApiOverview] = useState(false);
  const [sendClicked, setSendClicked] = useState(false);
  const [selectedTab, setSelectedTab] = useState('option2');
  const [stats, setStats] = useState([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [auditData, setAuditData] = useState([]);
  const [auditDataDetails, setAuditDataDetails] = useState([]);

  const entities = useSelector((state) => state.user.entities);
  const entity = useMemo(() => {
    return entities?.[selectedSidebarState?.id] || null;
  }, [selectedSidebarState?.id, entities]);

  const requestBoxStyle = {
    ...selectedTab === "option2" && { height: !isMinimized ? '45%' : '22%', overflow: 'hidden', transition: 'height 0.5s ease-in-out' }
  };

  const responseBoxStyle = {
    flexGrow: 1,
    height: !isMinimized ? '55%' : '78%',
    transition: 'height 0.5s ease-in-out'
  };

  useEffect(() => {
    if (api?.id) {
      console.log(selectedTab);
      // if (selectedTab === "option1") {
      //   getSummary();
      // }
      // dispatch(fetchStats({ entityId: api?.id })).then((resp) => {
      //   if (resp && resp.payload) {
      //     setStats(resp.payload);
      //   }
      // });

      // dispatch(fetchAuditData({ resourceName: 'entities', collectionId: api?.id })).then((res) => {
      //   if (res?.payload) {
      //     setAuditData(res.payload.data);
      //   }
      // });

      // dispatch(fetchAuditDataDetails({ resourceName: 'entities', collectionId: api?.id })).then((res) => {
      //   if (res?.payload) {
      //     setAuditDataDetails(res.payload.data);
      //   }
      // });
    }
  }, [api?.id, dispatch]);

  useEffect(() => {
    const fetchEntity = async () => {
      try {
        await dispatch(getEntity({ id: selectedSidebarState?.id }));
      } catch (error) {
        console.error('Error fetching entity:', error);
      }
    };

    if (selectedSidebarState?.id) {
      if (!entity) {
        fetchEntity();
      }
    }
  }, [selectedSidebarState, entity, dispatch]);

  const getSummary = async () => {
    if (selectedTab === "option1")
      await dispatch(fetchSummary(entity));
  }

  const toggleHeight = () => {
    setIsMinimized(!isMinimized);
  };

  const titleParts = [];
  if (state?.collection?.name) titleParts.push(state.collection.name);
  if (state?.folder) titleParts.push(state.folder.name);
  if (state?.collectionApi) titleParts.push(state.collectionApi.name);
  if (state?.folderApi) titleParts.push(state.folderApi.name);

  const renderTabContent = () => {
    return apiOverview ?
      <OverviewComponent resource={entity} />
      : selectedTab === 'option2' ?
        <ApiComponent state={state} api={api} entity={entity} onEntitySlugUpdate={onEntitySlugUpdate} />
        : selectedTab === 'option3' ?
          <MetricsComponent testData={stats} responseTimeData={stats} />
        : selectedTab === 'option4' ?
          <CollectedSamplesTable state={ state } api={ api } />
        : <Box sx={{ marginLeft: '10px', marginRight: '10px' }}><Audit resource={api} auditData={auditData} detailedData={auditDataDetails} /></Box>;
  };

  const handleSelectedOption = (option) => {
    setSelectedTab(option);
    setApiOverview(option === 'option1');
  }

  return (
    <AppProvider value={{ sendClicked, setSendClicked }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 100px)' }}>

        {/* Request */}
        <Box sx={ requestBoxStyle }>
          <Typography variant="h6" component="h4" gutterBottom sx={{ margin: '0px 10px', marginTop: '5px' }}>
            {titleParts.map((part, index) => (
              <span key={index}>
                {index > 0 && ' / '}
                {index === titleParts.length - 1 ? (
                  <b sx={{ color: '#6241D4' }}>{part}</b>
                ) : (
                  part
                )}
              </span>
            ))}
            <Link href="#" onClick={(e) => { e.preventDefault(); navigator.clipboard.writeText(window.location.href) }}>
              <LinkIcon sx={{ fontSize: '1.2rem', verticalAlign: 'middle', marginLeft: '10px' }} />
            </Link>
          </Typography>

          <ApiOptions selectedIcon={handleSelectedOption} api={api} />

          {/* <Box sx={{ flexGrow: 1, overflowY: 'auto', marginTop: '10px', marginBottom: '10px')' }}> */}
          <Box sx={{ height: 'calc(100% - 60px)', flexGrow: 1, overflowY: 'auto', '&::-webkit-scrollbar': { display: 'none' }, 'msOverflowStyle': 'none', 'scrollbarWidth': 'none', ...(selectedTab === 'option1' ? { marginLeft: '15px', marginRight: '15px' } : {}) }}>
            {renderTabContent()}
          </Box>
        </Box>

        {/* Response */}
        <Box sx={ responseBoxStyle }>
          { selectedTab === 'option2' && <ResponseComponent state={state} entity={entity} toggleHeight={toggleHeight} /> }
        </Box>
      </Box>
    </AppProvider>
  );
}

export default ApiScreen;