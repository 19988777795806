import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from '../../contexts/CustomSnackbarContext';
import { Box, Typography, TextField, Checkbox, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Button, IconButton, Snackbar } from '@mui/material';
import { Search, DeleteOutline as DeleteIcon } from '@mui/icons-material';

import {
  fetchVariables,
  createVariable,
  updateVariable,
  deleteVariable,
  bulkCreateVariables,
  bulkUpdateVariables
} from '../../redux-store/currentUserActions';

const VariablesComponent = ({ resourceType, resource }) => {
  const [filterVariables, setFilterVariables] = useState('');
  const [rows, setRows] = useState([]);
  const [displayedRows, setDisplayedRows] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  // const validateVariableName = (name) => {
  //   if (!name) return "Variable name cannot be empty";
  //   if (!/^[a-zA-Z0-9_]+$/.test(name)) return "Invalid characters in variable name. Only alphanumeric characters and underscores are allowed.";
  //   if (rows.some(row => row.key === name)) return "Variable name already exists";
  //   return "";
  // };

  const validateVariableName = (name) => {
    if (!name || !/^[a-zA-Z0-9_]+$/.test(name) || rows.some(row => row.var_name === name)) {
      return false;
    }

    return true;
  };

  const getVariables = async () => {
    try {
      const res = await dispatch(fetchVariables(resource));
      setRows(res.payload || []);
    } catch (error) {
      console.error("Failed to fetch variables:", error);
      openSnackbar({ message: "Failed to fetch variables.", severity: 'error' });
    }
  }

  useEffect(() => {
    getVariables();
  }, [resource]);

  const addNewRow = async () => {
    const newRow = {
      var_name: '',
      var_value: '',
      checked: true,
      resource_type: resourceType,
      resource_id: resource.id
    };

    const previousRow = rows.length > 0 ? rows[rows.length - 1] : null;
    if (previousRow && !previousRow?.id) {
      let row = {
        ...previousRow,
        resource_type: resourceType,
        resource_id: resource.id
      };

      // await dispatch(createVariable(row));
    }

    setRows(prevRows => [...prevRows, newRow]);
  };

  const handleInputChange = (index, field, value) => {
    setRows(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...updatedRows[index], [field]: value, resource_type: resourceType, resource_id: resource.id };

      if (field === 'key') {
        const isValid = validateVariableName(value);
        setValidationErrors(prevErrors => {
          const updatedErrors = { ...prevErrors };
          if (isValid || value === "") {
            delete updatedErrors[index];
          } else {
            updatedErrors[index] = "Invalid variable name.";
          }
          return updatedErrors;
        });
      }

      return updatedRows;
    });
  };

  const isSaveDisabled = Object.values(validationErrors).some(error => error !== "");

  const handleBulkSave = async () => {
    const newVariables = rows.filter(variable => !variable.id && variable.var_name.trim() !== "");
    const updatedVariables = rows.filter(variable => variable.id && variable.var_name.trim() !== "");

    try {
      if (newVariables.length > 0) {
        await dispatch(bulkCreateVariables(newVariables));
      }

      if (updatedVariables.length > 0) {
        await dispatch(bulkUpdateVariables(updatedVariables));
      }

      openSnackbar({ message: "Changes saved successfully.", severity: 'success' });
      getVariables();
    } catch (error) {
      console.error("Failed to save changes:", error);
      openSnackbar({ message: "Failed to save changes.", severity: 'error' });
    }
  };

  const handleDelete = async (index, row) => {
    try {
      if (row.id) {
        await dispatch(deleteVariable(row.id));
        openSnackbar({ message: "Variable deleted successfully.", severity: 'success' });
        getVariables();
      } else {
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
      }
    } catch (error) {
      console.error("Failed to delete variable:", error);
      openSnackbar({ message: "Failed to delete variable.", severity: 'error' });
    }
  };

  const handleFilterInputChange = (e) => {
    setFilterVariables(e.target.value);
  };

  useEffect(() => {
    if (filterVariables) {
      const filtered = rows.filter(row => row.var_name && row.var_name.toLowerCase().includes(filterVariables.toLowerCase()));
      setDisplayedRows(filtered);
    } else {
      setDisplayedRows(rows);
    }
  }, [rows, filterVariables]);

  return (
    <Box>
      <Box style={{ marginRight: '20%' }}>
        <Typography variant="body2" color="textSecondary">
          These variables are specific to this resource and its requests. Learn more about resource <a><u>variables</u></a>.
        </Typography>

        <TextField
          variant="outlined"
          size="small"
          id="token"
          name="token"
          value={filterVariables}
          onChange={handleFilterInputChange}
          placeholder="Filter variables"
          InputProps={{
            startAdornment: <Search color="action" />
          }}
          style={{ width: '40%' }}
        />
      </Box>

      <Box className='variable-box' sx={{ mt: 2 }}>
        {/* <Typography variant="body2" color="grey">
          Note: Use {'{{ variable_name }}'} format only
        </Typography> */}
        <TableContainer sx={{ borderRadius: '5px' }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Variable Name</TableCell>
                <TableCell>Initial Value</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      type="text"
                      id='variableName'
                      name="variableName"
                      defaultValue={row?.var_name}
                      onChange={(e) => handleInputChange(index, 'var_name', e.target.value)}
                      fullWidth
                      variant="outlined"
                      size="small"
                      error={!!validationErrors[index]}
                      helperText={validationErrors[index] || ' '}
                      style={{ marginTop: '10px' }}
                      FormHelperTextProps={{
                        style: { minHeight: '10px', height: '10px', fontSize: '10px' }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="text"
                      defaultValue={row?.var_value}
                      onChange={(e) => handleInputChange(index, 'var_value', e.target.value)}
                      fullWidth
                      variant="outlined"
                      size="small"
                      helperText={' '}
                      style={{ marginTop: '10px' }}
                      FormHelperTextProps={{
                        style: { minHeight: '10px', height: '10px', fontSize: '10px' }
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDelete(index, row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={5}>
                  <Button variant="text" color="primary" onClick={addNewRow}>
                    Add New Variable
                  </Button>
                  <Button variant="text" color="primary" onClick={handleBulkSave} disabled={isSaveDisabled}>
                    Save Changes
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default VariablesComponent;
