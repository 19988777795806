import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoadingState,
} from "./globalSlice";

const createTasks = createAsyncThunk(
  "createTasks",
  async (body, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    const data = {
      collection_ids: body.collectionIds,
      task_names: body.taskIds,
      tags: body.tags,
      test_scenario_description: body?.scenarioText
    };
    try {
      const authToken = localStorage.getItem("token");
      const response = await apiAgent({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/api/v1/ai/create_task/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: data,
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export {
  createTasks
};
